import { cn } from '../lib/utils';

interface LogoProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  showLocation?: boolean;
}

export default function Logo({ className, size = 'md', showLocation = true }: LogoProps) {
  const sizes = {
    sm: 'text-3xl',
    md: 'text-4xl',
    lg: 'text-6xl'
  };

  const locationSizes = {
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base'
  };

  return (
    <div className={cn('flex flex-col items-center', className)}>
      <div className="relative flex items-center">
        {/* Main Logo Text */}
        <div className="flex items-baseline">
          <span className={cn(
            'font-black tracking-tight bg-gradient-to-r from-white via-white/95 to-white/90 bg-clip-text text-transparent',
            sizes[size]
          )}>
            PRIVA
          </span>
          <span className={cn(
            'font-light bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent ml-1',
            {
              'text-2xl': size === 'sm',
              'text-3xl': size === 'md',
              'text-5xl': size === 'lg'
            }
          )}>
            Dr
          </span>
          <span className={cn(
            'text-blue-400/80',
            {
              'text-2xl': size === 'sm',
              'text-3xl': size === 'md',
              'text-5xl': size === 'lg'
            }
          )}>
            •
          </span>
        </div>
      </div>
      {/* Location Text */}
      {showLocation && (
        <span className={cn(
          'font-light tracking-widest uppercase text-gray-400',
          locationSizes[size]
        )}>
          of Harley Street
        </span>
      )}
    </div>
  );
}