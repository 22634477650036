import { ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const carePlans = [
  {
    title: 'Single Appointments',
    description: 'Single Appointments are ideal if you desire expert guidance on a specific health concern with the flexibility to schedule appointments at your convenience.',
    benefits: [
      'Benefit from expert advice on your health challenge',
      'Receive a customized, results-driven plan',
      'Enjoy the flexibility to book appointments as needed'
    ],
    ctaText: 'Learn More About Single Appointments'
  },
  {
    title: 'Test & 3 Appointments',
    description: 'Our Test & 3 Plan is perfect if you know exactly which diagnostic test you need and require precise support in interpreting and implementing the results.',
    benefits: [
      'Select the exact test you want',
      'Gain expert interpretation of your results',
      'Implement an effective plan based on precise data'
    ],
    ctaText: 'Learn More About Test & 3'
  },
  {
    title: '12-Week Care Plan',
    description: 'The 12-Week Care Plan is our most popular option, offering regular, structured appointments along with telephone and email support.',
    benefits: [
      'Receive a bespoke, structured care plan',
      'Benefit from regular appointments and continuous support',
      'Achieve significant improvements in your health over 12 weeks'
    ],
    ctaText: 'Learn More About 12-Week Care Plans'
  },
  {
    title: '6-Month Care Plan',
    description: 'Our most comprehensive solution for those committed to achieving lasting health transformation.',
    benefits: [
      'In-depth health assessment and personalized plan',
      'Regular consultations and progress tracking',
      'Comprehensive support throughout your journey'
    ],
    ctaText: 'Learn More About 6-Month Care Plans'
  }
];

export default function CarePlansSection() {
  return (
    <div className="container mx-auto px-4 py-20">
      <div className="max-w-4xl mx-auto text-center mb-16">
        <h2 className="text-3xl font-light text-white tracking-wide mb-6">
          Choose the Right Care Plan for Your Wellness Journey
        </h2>
        <p className="text-base text-gray-400 leading-relaxed font-light">
          At PrivaDr, we understand that every individual's health journey is unique. Whether you're seeking expert advice for a specific health challenge or looking for comprehensive support over several months, we have a range of tailored care plans to meet your needs.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {carePlans.map((plan, index) => (
          <div
            key={index}
            className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-8 hover:bg-white/[0.05] transition-all duration-300"
          >
            <h3 className="text-xl font-light text-white tracking-wide mb-4">
              {plan.title}
            </h3>
            <p className="text-sm text-gray-400 mb-6 font-light leading-relaxed">
              {plan.description}
            </p>
            <ul className="space-y-3 mb-8">
              {plan.benefits.map((benefit, idx) => (
                <li key={idx} className="text-sm text-gray-400 font-light flex items-start">
                  <span className="inline-block w-1.5 h-1.5 bg-blue-400 rounded-full mt-1.5 mr-3 flex-shrink-0" />
                  {benefit}
                </li>
              ))}
            </ul>
            <Button 
              variant="outline" 
              className="w-full group border-white/[0.05] hover:bg-white/[0.02]"
            >
              {plan.ctaText}
              <ArrowRight className="w-4 h-4 ml-2 opacity-50 group-hover:opacity-100" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}