import { Helmet } from 'react-helmet-async';
import { useSEO } from '../hooks/useSEO';
import TreatmentsGrid from '../components/lifestyle/TreatmentsGrid';
import ServicesSection from '../components/lifestyle/ServicesSection';
import CarePlansSection from '../components/lifestyle/CarePlansSection';
import IVTherapySection from '../components/lifestyle/IVTherapySection';
import VirtualClinicSection from '../components/lifestyle/VirtualClinicSection';
import RelatedArticles from '../components/lifestyle/RelatedArticles';

export default function LifestyleMedicine() {
  const { pageData } = useSEO('lifestyle-medicine');

  return (
    <>
      <Helmet>
        <title>{pageData?.metaTitle}</title>
        <meta name="description" content={pageData?.metaDescription} />
        <meta name="keywords" content={pageData?.keywords} />
      </Helmet>

      <div className="min-h-screen bg-[#0F1117]">
        {/* Hero Section */}
        <div className="relative min-h-[60vh] flex items-center">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?q=80&w=2000&auto=format&fit=crop"
              alt="Lifestyle Medicine"
              className="w-full h-full object-cover opacity-10"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
          </div>
          
          <div className="relative container mx-auto px-4 py-20 text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              {pageData?.h1 || 'Lifestyle Medicine'}
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Choose the Right Care Plan for Your Wellness Journey
            </p>
          </div>
        </div>

        {/* Treatments Grid */}
        <TreatmentsGrid />

        {/* Services Section */}
        <ServicesSection />

        {/* Care Plans Section */}
        <CarePlansSection />

        {/* IV Therapy Section */}
        <IVTherapySection />

        {/* Virtual Clinic Section */}
        <VirtualClinicSection />

        {/* Related Articles */}
        <RelatedArticles />
      </div>
    </>
  );
}