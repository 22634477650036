import { CheckCircle2 } from 'lucide-react';

const ivDrips = [
  {
    title: 'Wellness IV Drips',
    description: 'Premium intravenous therapy designed to enhance your wellness journey.',
    items: [
      'Increased Energy',
      'Beauty & Anti-Aging',
      'Immune Support',
      'Faster Recovery',
      'Weight Loss Support'
    ]
  },
  {
    title: 'Concierge IV Service',
    description: 'Exclusive mobile IV therapy service bringing wellness to your doorstep.',
    items: [
      'Home Treatment',
      'Hotel Service',
      'Corporate Wellness',
      'Event Support',
      'VIP Care'
    ]
  }
];

export default function IVTherapySection() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h2 className="text-2xl font-light text-white tracking-wide text-center mb-12">
        IV Drip Therapy
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {ivDrips.map((service, index) => (
          <div
            key={index}
            className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-8 hover:bg-white/[0.05] transition-all duration-300"
          >
            <h3 className="text-lg font-light text-white tracking-wide mb-4">{service.title}</h3>
            <p className="text-sm text-gray-400 mb-6 font-light leading-relaxed">{service.description}</p>
            <ul className="space-y-3">
              {service.items.map((item, idx) => (
                <li key={idx} className="flex items-center text-sm text-gray-400 font-light">
                  <CheckCircle2 className="w-4 h-4 text-blue-400 opacity-50 mr-3 flex-shrink-0" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}