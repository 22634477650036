import { useState, useEffect } from 'react';
import { Plus, Trash2, RefreshCw, Check, FileText, Calendar, Brain, Heart, Sparkles, Scale, Apple, Stethoscope, Save } from 'lucide-react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import { toast } from 'react-hot-toast';

interface Service {
  id: string;
  name: string;
  icon: JSX.Element;
  keywords: string[];
  usedKeywords: string[];
}

const defaultServices: Service[] = [
  {
    id: 'neurodevelopment',
    name: 'Neurodevelopment',
    icon: <Brain className="w-5 h-5 text-blue-500" />,
    keywords: [
      'ADHD symptoms in children',
      'autism assessment',
      'child development milestones',
      'learning disabilities',
      'sensory processing disorder'
    ],
    usedKeywords: []
  },
  {
    id: 'lifestyle',
    name: 'Lifestyle Medicine',
    icon: <Heart className="w-5 h-5 text-purple-500" />,
    keywords: [
      'healthy lifestyle habits',
      'stress management',
      'sleep optimization',
      'nutrition guidance',
      'exercise routines'
    ],
    usedKeywords: []
  },
  {
    id: 'dermatology',
    name: 'Dermatology',
    icon: <Sparkles className="w-5 h-5 text-yellow-500" />,
    keywords: [
      'skin care treatments',
      'acne solutions',
      'anti-aging procedures',
      'dermal fillers',
      'laser therapy'
    ],
    usedKeywords: []
  },
  {
    id: 'weight-management',
    name: 'Weight Management',
    icon: <Scale className="w-5 h-5 text-green-500" />,
    keywords: [
      'weight loss programs',
      'metabolic health',
      'body composition',
      'nutritional counseling',
      'sustainable weight loss'
    ],
    usedKeywords: []
  },
  {
    id: 'nutrition',
    name: 'Nutrition',
    icon: <Apple className="w-5 h-5 text-red-500" />,
    keywords: [
      'balanced diet plans',
      'meal planning',
      'dietary supplements',
      'food allergies',
      'nutritional deficiencies'
    ],
    usedKeywords: []
  },
  {
    id: 'general-health',
    name: 'General Health',
    icon: <Stethoscope className="w-5 h-5 text-cyan-500" />,
    keywords: [
      'preventive healthcare',
      'health screenings',
      'chronic disease management',
      'immune system support',
      'wellness checkups'
    ],
    usedKeywords: []
  }
];

const defaultBlogPrompt = `### Blog Writing Instructions

#### Input Requirements:
1. Title: Include primary keyword
2. Keywords: Primary and secondary keywords
3. Target Audience: Define intended readers
4. Call-to-Action: Service page link or consultation

#### Structure:
1. Title:
   - Clear, engaging, includes primary keyword
   - Example: "How Masseter Botox Can Relieve Jaw Pain Naturally"

2. Introduction:
   - Hook with common pain point
   - Primary keyword in first two sentences
   - Outline content purpose

3. Body Content:
   - Organized with H2, H3 headings
   - Natural keyword placement
   - Actionable advice
   - Evidence-based information
   - UK English spelling
   - Short paragraphs (3-4 sentences)

4. Visual Elements:
   - Relevant Unsplash images
   - Bullet points
   - Blockquotes
   - Info boxes

5. SEO Elements:
   - Primary keyword in:
     • Title
     • First 100 words
     • One subtitle
     • Conclusion
   - Secondary keywords throughout
   - Internal links to related content
   - External links to authoritative sources

6. Conclusion:
   - Summary of key points
   - Clear call-to-action
   - Service link or consultation booking

7. Style Guidelines:
   - Professional but conversational tone
   - UK English spelling
   - Medical accuracy
   - Evidence-based claims
   - Patient-friendly language`;

export default function BlogGenerator() {
  const [services, setServices] = useState<Service[]>(defaultServices);
  const [newService, setNewService] = useState('');
  const [newKeyword, setNewKeyword] = useState('');
  const [generating, setGenerating] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState(false);
  const [blogPrompt, setBlogPrompt] = useState(defaultBlogPrompt);
  const [lastGeneratedDates, setLastGeneratedDates] = useState<Record<string, string>>({});
  const [autoGenerate, setAutoGenerate] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const handleAddService = () => {
    if (!newService.trim()) {
      toast.error('Please enter a service name');
      return;
    }

    const id = newService.toLowerCase().replace(/\s+/g, '-');
    const newServiceObj: Service = {
      id,
      name: newService,
      icon: <Stethoscope className="w-5 h-5 text-blue-500" />,
      keywords: [],
      usedKeywords: []
    };

    setServices(prev => [...prev, newServiceObj]);
    setNewService('');
    setSelectedService(newServiceObj);
    toast.success('Service added successfully');
  };

  const handleAddKeyword = (serviceId: string) => {
    if (!newKeyword.trim()) {
      toast.error('Please enter a keyword');
      return;
    }

    setServices(prev => prev.map(service => {
      if (service.id === serviceId) {
        return {
          ...service,
          keywords: [...service.keywords, newKeyword]
        };
      }
      return service;
    }));

    setNewKeyword('');
    toast.success('Keyword added successfully');
  };

  const handleRemoveKeyword = (serviceId: string, keyword: string) => {
    setServices(prev => prev.map(service => {
      if (service.id === serviceId) {
        return {
          ...service,
          keywords: service.keywords.filter(k => k !== keyword)
        };
      }
      return service;
    }));
    toast.success('Keyword removed successfully');
  };

  const handleSavePrompt = () => {
    // In a real app, save to database
    setEditingPrompt(false);
    toast.success('Blog prompt template saved');
  };

  const handleGenerateBlog = async (serviceId: string) => {
    const service = services.find(s => s.id === serviceId);
    if (!service || service.keywords.length === 0) {
      toast.error('No keywords available for this service');
      return;
    }

    setGenerating(true);
    try {
      // Get a random keyword
      const randomIndex = Math.floor(Math.random() * service.keywords.length);
      const keyword = service.keywords[randomIndex];

      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Update last generated date
      setLastGeneratedDates(prev => ({
        ...prev,
        [serviceId]: new Date().toISOString().split('T')[0]
      }));

      // Move the keyword to usedKeywords
      setServices(prev => prev.map(s => {
        if (s.id === serviceId) {
          return {
            ...s,
            keywords: s.keywords.filter(k => k !== keyword),
            usedKeywords: [...s.usedKeywords, keyword]
          };
        }
        return s;
      }));

      toast.success(`Blog generated for ${service.name}: ${keyword}`);
    } catch (error) {
      toast.error('Failed to generate blog');
    } finally {
      setGenerating(false);
    }
  };

  useEffect(() => {
    if (autoGenerate) {
      const interval = setInterval(() => {
        services.forEach(service => {
          const lastDate = lastGeneratedDates[service.id];
          const today = new Date().toISOString().split('T')[0];
          
          if (!lastDate || lastDate < today) {
            handleGenerateBlog(service.id);
          }
        });
      }, 1000 * 60 * 60); // Check every hour

      return () => clearInterval(interval);
    }
  }, [autoGenerate, services, lastGeneratedDates]);

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold text-white">Auto-Blog Generator</h2>
        <div className="flex items-center space-x-4">
          <Button
            variant={autoGenerate ? 'default' : 'outline'}
            onClick={() => setAutoGenerate(!autoGenerate)}
          >
            <Calendar className="h-4 w-4 mr-2" />
            {autoGenerate ? 'Auto-Generate On' : 'Auto-Generate Off'}
          </Button>
          <Button
            variant={showPrompt ? 'default' : 'outline'}
            onClick={() => setShowPrompt(!showPrompt)}
          >
            <FileText className="h-4 w-4 mr-2" />
            {showPrompt ? 'Hide Prompt' : 'Show Prompt'}
          </Button>
        </div>
      </div>

      {/* Blog Prompt Template */}
      {showPrompt && (
        <div className="bg-white/5 backdrop-blur-sm rounded-lg border border-white/10 p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-white">Blog Writing Template</h3>
            <div className="flex items-center space-x-4">
              {editingPrompt ? (
                <Button onClick={handleSavePrompt}>
                  <Save className="h-4 w-4 mr-2" />
                  Save Changes
                </Button>
              ) : (
                <Button variant="outline" onClick={() => setEditingPrompt(true)}>
                  Edit Template
                </Button>
              )}
            </div>
          </div>
          {editingPrompt ? (
            <textarea
              value={blogPrompt}
              onChange={(e) => setBlogPrompt(e.target.value)}
              className="w-full h-[500px] bg-white/10 border border-white/20 rounded-lg p-4 text-gray-300 font-mono text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          ) : (
            <pre className="whitespace-pre-wrap text-gray-300 font-mono text-sm">
              {blogPrompt}
            </pre>
          )}
        </div>
      )}

      {/* Services Grid */}
      <div className="grid grid-cols-12 gap-6">
        {/* Services List */}
        <div className="col-span-3 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10 p-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-white">Services</h3>
            <Button size="sm" onClick={() => setSelectedService(null)}>
              <Plus className="h-4 w-4 mr-2" />
              Add New
            </Button>
          </div>
          <div className="space-y-2">
            {services.map(service => (
              <button
                key={service.id}
                onClick={() => setSelectedService(service)}
                className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-colors ${
                  selectedService?.id === service.id
                    ? 'bg-blue-500/20 text-blue-500'
                    : 'text-gray-400 hover:text-white hover:bg-white/5'
                }`}
              >
                {service.icon}
                <span>{service.name}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Service Details */}
        <div className="col-span-9">
          {selectedService ? (
            <div className="bg-white/5 backdrop-blur-sm rounded-lg border border-white/10 p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-3">
                  {selectedService.icon}
                  <div>
                    <h3 className="text-lg font-medium text-white">{selectedService.name}</h3>
                    {lastGeneratedDates[selectedService.id] && (
                      <p className="text-sm text-gray-400">
                        Last generated: {lastGeneratedDates[selectedService.id]}
                      </p>
                    )}
                  </div>
                </div>
                <Button
                  onClick={() => handleGenerateBlog(selectedService.id)}
                  disabled={generating || selectedService.keywords.length === 0}
                >
                  {generating ? (
                    <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <Plus className="h-4 w-4 mr-2" />
                  )}
                  Generate Blog
                </Button>
              </div>

              <div className="space-y-6">
                {/* Add Keyword */}
                <div className="flex items-center space-x-4">
                  <Input
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    placeholder="Add keyword..."
                    className="flex-1"
                  />
                  <Button onClick={() => handleAddKeyword(selectedService.id)}>
                    <Plus className="h-4 w-4 mr-2" />
                    Add Keyword
                  </Button>
                </div>

                {/* Keywords Grid */}
                <div className="grid grid-cols-2 gap-6">
                  {/* Available Keywords */}
                  <div className="bg-white/5 rounded-lg p-4">
                    <h4 className="text-sm font-medium text-gray-400 mb-4">Available Keywords</h4>
                    <div className="space-y-2">
                      {selectedService.keywords.map((keyword, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between bg-white/5 rounded-lg p-2"
                        >
                          <span className="text-gray-300">{keyword}</span>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleRemoveKeyword(selectedService.id, keyword)}
                          >
                            <Trash2 className="h-4 w-4 text-red-500" />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Used Keywords */}
                  <div className="bg-white/5 rounded-lg p-4">
                    <h4 className="text-sm font-medium text-gray-400 mb-4">Used Keywords</h4>
                    <div className="space-y-2">
                      {selectedService.usedKeywords.map((keyword, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between bg-white/5 rounded-lg p-2"
                        >
                          <span className="text-gray-300">{keyword}</span>
                          <Check className="h-4 w-4 text-green-500" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white/5 backdrop-blur-sm rounded-lg border border-white/10 p-6">
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-white">Add New Service</h3>
                <Input
                  value={newService}
                  onChange={(e) => setNewService(e.target.value)}
                  placeholder="Service name..."
                  className="w-full"
                />
                <Button onClick={handleAddService}>
                  <Plus className="h-4 w-4 mr-2" />
                  Add Service
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}