import { Link } from 'react-router-dom';
import { Clock } from 'lucide-react';

const articles = [
  {
    title: "Understanding Metabolic Health: Beyond Weight Loss",
    description: "Discover how metabolic health impacts weight management and overall wellness. Learn about the key factors that influence your metabolism and strategies for optimization.",
    image: "https://images.unsplash.com/photo-1511690743698-d9d85f2fbf38?q=80&w=800&auto=format&fit=crop",
    date: "March 25, 2024",
    readTime: "6 min read",
    slug: "understanding-metabolic-health"
  },
  {
    title: "Breaking Free from Food Addiction",
    description: "Expert insights into understanding and overcoming food addiction. Learn about the psychological aspects of eating habits and evidence-based strategies for lasting change.",
    image: "https://images.unsplash.com/photo-1498837167922-ddd27525d352?q=80&w=800&auto=format&fit=crop",
    date: "March 24, 2024",
    readTime: "5 min read",
    slug: "breaking-free-food-addiction"
  },
  {
    title: "Type 2 Diabetes and Weight Management",
    description: "Explore the crucial connection between weight management and Type 2 diabetes. Learn how modern weight loss treatments can help improve blood sugar control.",
    image: "https://images.unsplash.com/photo-1505576399279-565b52d4ac71?q=80&w=800&auto=format&fit=crop",
    date: "March 23, 2024",
    readTime: "7 min read",
    slug: "diabetes-weight-management"
  }
];

export default function RelatedArticles() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h2 className="text-2xl font-light text-white mb-12">Related Articles</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {articles.map((article, index) => (
          <Link
            key={index}
            to={`/blog/${article.slug}`}
            className="group bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] overflow-hidden hover:border-blue-500/50 transition-all"
          >
            <div className="relative h-48 overflow-hidden">
              <img
                src={article.image}
                alt={article.title}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-[#0F1117] to-transparent opacity-60" />
            </div>
            
            <div className="p-6">
              <h3 className="text-lg font-medium text-white mb-2 group-hover:text-blue-400 transition-colors line-clamp-2">
                {article.title}
              </h3>
              <p className="text-sm text-gray-400 mb-4 line-clamp-2">
                {article.description}
              </p>
              <div className="flex items-center justify-between text-sm text-gray-500">
                <span>{article.date}</span>
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-1" />
                  <span>{article.readTime}</span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}