import { Activity, Brain, Sparkles, Stethoscope, Crown, Scale, Syringe, Globe } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';

export default function HeroSection() {
  const projects = [
    {
      icon: <Crown className="w-8 h-8" />,
      title: "PrivaLife",
      href: "/privalife",
      color: "text-purple-500"
    },
    {
      icon: <Globe className="w-8 h-8" />,
      title: "International Patients",
      href: "/international-patients",
      color: "text-blue-500"
    },
    {
      icon: <Stethoscope className="w-8 h-8" />,
      title: "General Practice",
      href: "/general-practice",
      color: "text-green-500"
    },
    {
      icon: <Activity className="w-8 h-8" />,
      title: "Lifestyle Medicine",
      href: "/lifestyle-medicine",
      color: "text-yellow-500"
    },
    {
      icon: <Brain className="w-8 h-8" />,
      title: "Neuro-Development",
      href: "/neurodevelopment",
      color: "text-purple-500"
    },
    {
      icon: <Scale className="w-8 h-8" />,
      title: "Weight Loss",
      href: "/weight-loss",
      color: "text-pink-500"
    },
    {
      icon: <Syringe className="w-8 h-8" />,
      title: "MedSpa | Aesthetic",
      href: "/medspa",
      color: "text-indigo-500"
    }
  ];

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <div className="relative container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* Left Column */}
          <div className="space-y-8">
            <div className="space-y-8">
              <div className="flex items-center justify-center space-x-2 mb-8">
                <Stethoscope className="h-8 w-8 text-blue-500" />
                <span className="text-blue-500 font-semibold">Leading Medical Services</span>
              </div>
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-center">
                <span className="text-white">PRIVA Dr.</span>
              </h1>
              <h2 className="text-3xl font-light text-center">
                <span className="text-white">Adding Life </span>
                <span className="text-blue-500">To The Years</span>
              </h2>
              <p className="text-xl text-gray-400 text-center leading-relaxed">
                We are forward thinking, consumer orientated group, with the mission to inform educate and provide well researched scientific solutions.
              </p>
            </div>
          </div>

          {/* Right Column */}
          <div className="bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 p-8">
            <h3 className="text-2xl font-bold text-white mb-8">
              Our Services
            </h3>
            <div className="grid grid-cols-2 gap-6">
              {projects.map((project, index) => (
                <Link
                  key={index}
                  to={project.href}
                  className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm p-6 border border-white/10 transition-all hover:border-blue-500/50"
                >
                  <div className="flex flex-col items-center text-center space-y-4">
                    <div className={`p-3 rounded-full bg-white/5 ${project.color} group-hover:bg-white/10 group-hover:scale-110 transition-all duration-300`}>
                      {project.icon}
                    </div>
                    <h4 className="text-sm font-medium text-white">
                      {project.title}
                    </h4>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}