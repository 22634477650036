import { useState } from 'react';
import { Database, RefreshCw } from 'lucide-react';
import Button from '../ui/Button';
import { setupDatabase } from '../../lib/setupDatabase';

export default function DatabaseSetup() {
  const [initializing, setInitializing] = useState(false);

  const handleInitialize = async () => {
    setInitializing(true);
    try {
      await setupDatabase();
    } finally {
      setInitializing(false);
    }
  };

  return (
    <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <Database className="w-5 h-5 text-blue-500" />
          <h2 className="text-xl font-semibold text-white">Database Setup</h2>
        </div>
        <Button onClick={handleInitialize} disabled={initializing}>
          {initializing ? (
            <>
              <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
              Initializing...
            </>
          ) : (
            <>
              <Database className="w-4 h-4 mr-2" />
              Initialize Database
            </>
          )}
        </Button>
      </div>
      <p className="text-gray-400">
        Initialize or reset the database tables and initial data. This will create all necessary tables and insert default data.
      </p>
    </div>
  );
}