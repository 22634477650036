import { InputHTMLAttributes, forwardRef, ReactNode } from 'react';
import { cn } from '../../lib/utils';

interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement | HTMLSelectElement>, 'type'> {
  label?: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'date' | 'select';
  children?: ReactNode;
}

const Input = forwardRef<HTMLInputElement | HTMLSelectElement, InputProps>(
  ({ className, label, type = 'text', children, ...props }, ref) => {
    const baseClasses = cn(
      'block w-full rounded-md border px-3 py-2 placeholder-gray-400',
      'focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'bg-white/10 border-white/20 text-white',
      className
    );

    return (
      <div className="space-y-2">
        {label && (
          <label className="block text-sm font-medium text-gray-300">
            {label}
          </label>
        )}
        {type === 'select' ? (
          <select
            className={baseClasses}
            ref={ref as React.ForwardedRef<HTMLSelectElement>}
            {...props}
          >
            {children}
          </select>
        ) : (
          <input
            type={type}
            className={baseClasses}
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            {...props}
          />
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;