import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import PatientHeader from '../components/PatientHeader';
import ConnersFormLinks from '../components/ConnersFormLinks';
import AssessmentCard from '../components/AssessmentCard';

export default function PatientDashboard() {
  const { user } = useAuth();
  const [editingProfile, setEditingProfile] = useState(false);

  const patientData = {
    firstName: user?.user_metadata?.firstName || '',
    lastName: user?.user_metadata?.lastName || '',
    dateOfBirth: user?.user_metadata?.dateOfBirth || '',
    gender: user?.user_metadata?.gender || '',
    school: {
      name: user?.user_metadata?.schoolName || '',
      contactName: user?.user_metadata?.schoolContactName || '',
      contactEmail: user?.user_metadata?.schoolContactEmail || '',
      contactPhone: user?.user_metadata?.schoolContactPhone || ''
    },
    history: user?.user_metadata?.history || 'No history provided'
  };

  const assessments = [
    {
      title: "Parent Assessment Form",
      description: "Comprehensive parent questionnaire for ADHD evaluation",
      status: 'pending',
      progress: 0,
      type: 'parent'
    },
    {
      title: "Teacher Assessment Form",
      description: "School-based behavioral assessment questionnaire",
      status: 'pending',
      progress: 0,
      type: 'teacher'
    },
    {
      title: "Development History Form",
      description: "Detailed developmental history questionnaire",
      status: 'pending',
      progress: 0,
      type: 'development'
    }
  ];

  return (
    <div className="pt-20">
      <div className="max-w-7xl mx-auto px-4 space-y-8">
        {/* Patient Header */}
        <PatientHeader
          patient={patientData}
          onEdit={() => setEditingProfile(true)}
        />

        {/* ADHD Assessment Section */}
        <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-white">Initial Screening</h2>
            <div className="flex items-center space-x-2 text-gray-400">
              <span>Package Price:</span>
              <span className="text-xl font-bold text-white">£450</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {assessments.map((assessment, index) => (
              <AssessmentCard
                key={index}
                {...assessment}
              />
            ))}
          </div>
        </div>

        {/* Conners Form Links */}
        <ConnersFormLinks
          parentLink={user?.user_metadata?.conners_parent_link}
          teacherLink={user?.user_metadata?.conners_teacher_link}
        />
      </div>
    </div>
  );
}