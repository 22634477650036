import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { cn } from '../lib/utils';

interface DetailedAssessmentViewProps {
  title: string;
  scores: {
    attention: number;
    hyperactivity: number;
    oppositional: number;
    total: number;
  };
  responses: Record<string, string>;
  questions: Array<{
    id: string;
    text: string;
    category: string;
    options: string[];
  }>;
}

export default function DetailedAssessmentView({
  title,
  scores,
  responses,
  questions
}: DetailedAssessmentViewProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const categories = Array.from(new Set(questions.map(q => q.category)));

  return (
    <div className="bg-white/5 p-6 rounded-lg">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between text-left"
      >
        <h4 className="text-lg font-medium text-white">{title}</h4>
        {isExpanded ? (
          <ChevronUp className="h-5 w-5 text-gray-400" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-400" />
        )}
      </button>

      <div className={cn("mt-4", !isExpanded && "hidden")}>
        {/* Scores Summary */}
        <div className="grid grid-cols-4 gap-4 mb-6">
          <div className="bg-white/5 p-4 rounded-lg">
            <div className="text-sm text-gray-400">Attention</div>
            <div className="text-2xl font-bold text-white">{scores.attention}/27</div>
          </div>
          <div className="bg-white/5 p-4 rounded-lg">
            <div className="text-sm text-gray-400">Hyperactivity</div>
            <div className="text-2xl font-bold text-white">{scores.hyperactivity}/27</div>
          </div>
          <div className="bg-white/5 p-4 rounded-lg">
            <div className="text-sm text-gray-400">Oppositional</div>
            <div className="text-2xl font-bold text-white">{scores.oppositional}/24</div>
          </div>
          <div className="bg-white/5 p-4 rounded-lg">
            <div className="text-sm text-gray-400">Total Score</div>
            <div className="text-2xl font-bold text-white">{scores.total}/78</div>
          </div>
        </div>

        {/* Detailed Responses */}
        <div className="space-y-6">
          {categories.map(category => (
            <div key={category}>
              <h5 className="text-md font-medium text-white mb-4">{category}</h5>
              <div className="space-y-4">
                {questions
                  .filter(q => q.category === category)
                  .map(question => (
                    <div key={question.id} className="bg-white/5 p-4 rounded-lg">
                      <p className="text-gray-300 mb-2">{question.text}</p>
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-400">Response:</span>
                        <span className={cn(
                          "text-sm font-medium",
                          responses[question.id] === 'Very Often' && "text-red-400",
                          responses[question.id] === 'Often' && "text-orange-400",
                          responses[question.id] === 'Sometimes' && "text-yellow-400",
                          responses[question.id] === 'Never/Rarely' && "text-green-400"
                        )}>
                          {responses[question.id] || 'Not answered'}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}