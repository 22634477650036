import { ArrowLeft, Brain, Star, Award, Building2, FileText } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';

export default function DoctorProfile() {
  const doctor = {
    id: 'dr-anwar-haq',
    name: 'Dr. Anwar Haq',
    qualifications: 'MBBS, DCH, MRCPI, FRCPI, FRCPCH (UK)',
    title: 'Consultant Pediatrician',
    subtitle: 'Specialist in Child Development and Neurodevelopmental Disorders',
    image: 'https://raw.githubusercontent.com/medacles/medacles.github.io/main/img/dr-anwar-haq.jpg',
    experience: '40+ years clinical experience',
    description: 'Dr. Anwar Haq is a distinguished pediatrician with over 40 years of clinical experience, specializing in developmental and neurodevelopmental disorders such as autism spectrum disorder (ASD), ADHD, and learning disabilities. He is a Fellow of the Royal College of Physicians in Ireland and the Royal College of Paediatrics and Child Health (UK), reflecting his dedication to advancing child healthcare.',
    specialties: [
      'Autism Spectrum Disorder (ASD)',
      'ADHD',
      'Developmental Delays',
      'Learning Disabilities',
      'Speech and Language Disorders',
      'Multidisciplinary Development Assessments'
    ],
    education: [
      {
        degree: 'F.R.C.P.C.H.',
        institution: 'Royal College of Paediatrics and Child Health (UK)',
        year: '1997'
      },
      {
        degree: 'F.R.C.P.',
        institution: 'Royal College of Physicians in Ireland',
        year: '1996'
      },
      {
        degree: 'M.R.C.P.I.',
        institution: 'Royal College of Physicians in Ireland',
        year: '1986'
      },
      {
        degree: 'D.C.H.',
        institution: 'University College Dublin, National University of Ireland',
        year: '1980'
      },
      {
        degree: 'M.B.B.S.',
        institution: 'Nishtar Medical College, University of Punjab, Pakistan',
        year: '1973'
      },
      {
        degree: 'B.Sc.',
        institution: 'Forman Christian College, Lahore, Pakistan',
        year: '1967'
      }
    ],
    experience_details: [
      {
        role: 'Consultant Pediatrician',
        institution: 'Surrey and Borders Partnership NHS Trust',
        period: '2009-Present'
      },
      {
        role: 'Consultant Pediatrician',
        institution: 'Central and West London NHS Trust',
        period: '2010-2015'
      },
      {
        role: 'Consultant Pediatrician',
        institution: 'Johns Hopkins Aramco Healthcare',
        period: '1989-2009'
      }
    ],
    memberships: [
      'Member, Royal College of Physicians of Ireland (MRCPI)',
      'Fellow, Royal College of Paediatrics and Child Health (FRCPCH), UK',
      'Fellow, Royal College of Physicians of Ireland (FRCP)',
      'Member, British Association of Community Child Health (BACCH)'
    ],
    quote: 'Every child is unique, with their own strengths and challenges. In understanding conditions like autism and ADHD, it\'s not just about diagnosing a disorder; it\'s about recognizing each child\'s potential and supporting their journey to thrive.'
  };

  return (
    <div className="pt-20">
      <div className="max-w-6xl mx-auto px-4">
        {/* Back Button */}
        <Link
          to="/doctors"
          className="inline-flex items-center text-gray-400 hover:text-white mb-8"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Doctors
        </Link>

        {/* Profile Header */}
        <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8 mb-8">
          <div className="flex flex-col md:flex-row gap-8">
            <div className="relative w-48 h-48 flex-shrink-0">
              <img
                src={doctor.image}
                alt={doctor.name}
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute top-4 right-4">
                <div className="bg-blue-500/20 backdrop-blur-sm rounded-full p-2">
                  <Star className="w-5 h-5 text-blue-400" />
                </div>
              </div>
            </div>

            <div className="flex-grow space-y-4">
              <div>
                <h1 className="text-3xl font-bold text-white">{doctor.name}</h1>
                <p className="text-blue-400">{doctor.qualifications}</p>
              </div>

              <div>
                <h2 className="text-xl text-white mb-2">{doctor.title}</h2>
                <p className="text-gray-400">{doctor.subtitle}</p>
              </div>

              <p className="text-gray-300">{doctor.description}</p>

              <div className="space-y-2">
                <h3 className="text-sm font-medium text-gray-400">Specialties</h3>
                <div className="flex flex-wrap gap-2">
                  {doctor.specialties.map((specialty, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-500/10 text-blue-400"
                    >
                      <Brain className="w-4 h-4 mr-2" />
                      {specialty}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Quote */}
        <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8 mb-8">
          <blockquote className="text-xl italic text-gray-300">
            "{doctor.quote}"
          </blockquote>
        </div>

        {/* Education & Experience */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          {/* Education */}
          <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8">
            <div className="flex items-center space-x-3 mb-6">
              <Award className="h-6 w-6 text-blue-500" />
              <h2 className="text-xl font-semibold text-white">Education</h2>
            </div>
            <div className="space-y-4">
              {doctor.education.map((edu, index) => (
                <div key={index} className="border-l-2 border-blue-500/50 pl-4">
                  <h3 className="text-white font-medium">{edu.degree}</h3>
                  <p className="text-gray-400">{edu.institution}</p>
                  <p className="text-sm text-gray-500">{edu.year}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Experience */}
          <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8">
            <div className="flex items-center space-x-3 mb-6">
              <Building2 className="h-6 w-6 text-blue-500" />
              <h2 className="text-xl font-semibold text-white">Experience</h2>
            </div>
            <div className="space-y-4">
              {doctor.experience_details.map((exp, index) => (
                <div key={index} className="border-l-2 border-blue-500/50 pl-4">
                  <h3 className="text-white font-medium">{exp.role}</h3>
                  <p className="text-gray-400">{exp.institution}</p>
                  <p className="text-sm text-gray-500">{exp.period}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Professional Memberships */}
        <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8">
          <div className="flex items-center space-x-3 mb-6">
            <FileText className="h-6 w-6 text-blue-500" />
            <h2 className="text-xl font-semibold text-white">Professional Memberships</h2>
          </div>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {doctor.memberships.map((membership, index) => (
              <li
                key={index}
                className="flex items-center space-x-2 text-gray-300"
              >
                <Star className="h-4 w-4 text-blue-500" />
                <span>{membership}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}