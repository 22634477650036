import { Brain, Heart, Shield, Users } from 'lucide-react';

export default function FeaturesSection() {
  const features = [
    {
      icon: <Brain className="h-6 w-6" />,
      title: "Expert Care",
      description: "Specialized medical professionals with extensive experience"
    },
    {
      icon: <Heart className="h-6 w-6" />,
      title: "Patient-Centered",
      description: "Personalized care tailored to individual needs"
    },
    {
      icon: <Shield className="h-6 w-6" />,
      title: "Quality Assured",
      description: "Adherence to highest medical standards"
    },
    {
      icon: <Users className="h-6 w-6" />,
      title: "Family Focused",
      description: "Comprehensive support for the whole family"
    }
  ];

  return (
    <div className="py-20 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-white mb-12">Why Choose Medacles</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="group bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-6 hover:border-blue-500/50 transition-all"
            >
              <div className="flex flex-col items-center text-center space-y-4">
                <div className="p-3 rounded-full bg-blue-500/10 text-blue-500 group-hover:scale-110 transition-transform">
                  {feature.icon}
                </div>
                <h3 className="text-lg font-semibold text-white">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}