import { useState } from 'react';
import { Wand2, Download, Copy, Check, Edit2, Save } from 'lucide-react';
import Button from './ui/Button';
import { generateReport } from '../lib/openai';
import { toast } from 'react-hot-toast';

interface AIReportGeneratorProps {
  patient: {
    name: string;
    dateOfBirth: string;
    assessmentData: {
      parent: {
        attention: number;
        hyperactivity: number;
        oppositional: number;
        total: number;
      };
      teacher: {
        attention: number;
        hyperactivity: number;
        oppositional: number;
        total: number;
      };
      development: {
        responses: Record<string, string>;
      };
    };
  };
}

export default function AIReportGenerator({ patient }: AIReportGeneratorProps) {
  const [generating, setGenerating] = useState(false);
  const [report, setReport] = useState('');
  const [editedReport, setEditedReport] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleGenerateReport = async () => {
    if (!patient?.assessmentData?.parent || !patient?.assessmentData?.teacher) {
      toast.error('Assessment data is incomplete');
      return;
    }

    setGenerating(true);
    try {
      const generatedReport = await generateReport({
        name: patient.name,
        age: new Date().getFullYear() - new Date(patient.dateOfBirth).getFullYear(),
        assessmentResults: {
          parentForm: {
            score: patient.assessmentData.parent.total,
            severity: getSeverityLevel(patient.assessmentData.parent.total)
          },
          teacherForm: {
            score: patient.assessmentData.teacher.total,
            severity: getSeverityLevel(patient.assessmentData.teacher.total)
          }
        }
      });
      
      if (generatedReport) {
        setReport(generatedReport);
        setEditedReport(generatedReport);
        toast.success('Report generated successfully');
      }
    } catch (error) {
      console.error('Failed to generate report:', error);
      toast.error('Failed to generate report. Please try again.');
    } finally {
      setGenerating(false);
    }
  };

  const getSeverityLevel = (score: number) => {
    if (score >= 60) return 'Severe';
    if (score >= 40) return 'Moderate';
    return 'Mild';
  };

  const handleSaveEdit = () => {
    setReport(editedReport);
    setIsEditing(false);
    toast.success('Report updated successfully');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(report);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    toast.success('Report copied to clipboard');
  };

  const downloadReport = () => {
    const blob = new Blob([report], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${patient.name.replace(/\s+/g, '_')}_ADHD_Assessment_Report.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    toast.success('Report downloaded successfully');
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h3 className="text-lg font-medium text-white">AI Report Generator</h3>
          <p className="text-sm text-gray-400">
            Generate and customize assessment reports based on collected data
          </p>
        </div>
        <Button
          onClick={handleGenerateReport}
          disabled={generating}
          className="flex items-center space-x-2"
        >
          <Wand2 className="h-4 w-4" />
          <span>{generating ? 'Generating...' : 'Generate Report'}</span>
        </Button>
      </div>

      {report && (
        <div className="space-y-4">
          <div className="bg-white/5 p-4 rounded-lg">
            {isEditing ? (
              <textarea
                value={editedReport}
                onChange={(e) => setEditedReport(e.target.value)}
                className="w-full h-[500px] bg-transparent text-white font-mono text-sm resize-none focus:outline-none"
                placeholder="Edit report content..."
              />
            ) : (
              <pre className="whitespace-pre-wrap text-white font-mono text-sm">
                {report}
              </pre>
            )}
          </div>

          <div className="flex justify-end space-x-4">
            {isEditing ? (
              <>
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsEditing(false);
                    setEditedReport(report);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleSaveEdit}>
                  <Save className="h-4 w-4 mr-2" />
                  Save Changes
                </Button>
              </>
            ) : (
              <>
                <Button variant="outline" onClick={() => setIsEditing(true)}>
                  <Edit2 className="h-4 w-4 mr-2" />
                  Edit Report
                </Button>
                <Button variant="outline" onClick={copyToClipboard}>
                  {copied ? (
                    <Check className="h-4 w-4 mr-2" />
                  ) : (
                    <Copy className="h-4 w-4 mr-2" />
                  )}
                  {copied ? 'Copied!' : 'Copy to Clipboard'}
                </Button>
                <Button onClick={downloadReport}>
                  <Download className="h-4 w-4 mr-2" />
                  Download Report
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}