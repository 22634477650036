import { Link } from 'react-router-dom';
import { Clock, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const articles = [
  {
    title: "Why Choose Concierge Medicine?",
    description: "Discover the benefits of personalized healthcare and how concierge medicine is revolutionizing patient care.",
    image: "https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=800&auto=format&fit=crop",
    date: "March 25, 2024",
    readTime: "5 min read",
    slug: "why-choose-concierge-medicine",
    serviceLink: {
      text: "Learn About PrivaLife Membership",
      url: "/privalife"
    }
  },
  {
    title: "The Future of Focused Medicine",
    description: "How specialized medical care is leading to better patient outcomes and more efficient healthcare delivery.",
    image: "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?q=80&w=800&auto=format&fit=crop",
    date: "March 24, 2024",
    readTime: "6 min read",
    slug: "future-focused-medicine",
    serviceLink: {
      text: "Explore Our Focused Care Programs",
      url: "/privalife"
    }
  },
  {
    title: "Personalized Healthcare Journey",
    description: "Understanding the value of customized medical care and long-term health partnerships.",
    image: "https://images.unsplash.com/photo-1579684385127-1ef15d508118?q=80&w=800&auto=format&fit=crop",
    date: "March 23, 2024",
    readTime: "4 min read",
    slug: "personalized-healthcare-journey",
    serviceLink: {
      text: "Start Your Healthcare Journey",
      url: "/privalife"
    }
  }
];

export default function RelatedArticles() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h2 className="text-2xl font-light text-white mb-12">Related Articles</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {articles.map((article, index) => (
          <div
            key={index}
            className="group bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] overflow-hidden hover:border-blue-500/50 transition-all"
          >
            <Link to={`/blog/${article.slug}`}>
              <div className="relative h-48 overflow-hidden">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#0F1117] to-transparent opacity-60" />
              </div>
              
              <div className="p-6">
                <h3 className="text-lg font-medium text-white mb-2 group-hover:text-blue-400 transition-colors line-clamp-2">
                  {article.title}
                </h3>
                <p className="text-sm text-gray-400 mb-4 line-clamp-2">
                  {article.description}
                </p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <span>{article.date}</span>
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-1" />
                    <span>{article.readTime}</span>
                  </div>
                </div>
              </div>
            </Link>
            
            {/* Service Link */}
            <div className="px-6 pb-6">
              <Link to={article.serviceLink.url}>
                <Button variant="outline" className="w-full group">
                  {article.serviceLink.text}
                  <ArrowRight className="w-4 h-4 ml-2 opacity-50 group-hover:opacity-100" />
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}