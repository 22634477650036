import { User, School, Calendar, Mail, Phone } from 'lucide-react';
import Button from './ui/Button';

interface PatientHeaderProps {
  patient: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    school: {
      name: string;
      contactName: string;
      contactEmail: string;
      contactPhone: string;
    };
    history: string;
  };
  onEdit: () => void;
}

export default function PatientHeader({ patient, onEdit }: PatientHeaderProps) {
  const calculateAge = (dob: string) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
      <div className="flex items-start justify-between mb-6">
        <div className="flex items-start space-x-6">
          {/* Profile Icon */}
          <div className="h-16 w-16 rounded-full bg-blue-500/10 flex items-center justify-center">
            <User className="h-8 w-8 text-blue-500" />
          </div>

          {/* Patient Details */}
          <div className="space-y-4">
            {/* Basic Info */}
            <div>
              <h1 className="text-2xl font-bold text-white">
                {patient.firstName} {patient.lastName}
              </h1>
              <div className="flex items-center space-x-4 text-gray-400 mt-1">
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  <span>DOB: {new Date(patient.dateOfBirth).toLocaleDateString()}</span>
                </div>
                <span>•</span>
                <span>Age: {calculateAge(patient.dateOfBirth)} years</span>
                <span>•</span>
                <span>Gender: {patient.gender}</span>
              </div>
            </div>

            {/* School Information */}
            <div className="bg-white/5 rounded-lg p-4 space-y-2">
              <div className="flex items-center text-gray-300">
                <School className="h-4 w-4 mr-2 text-blue-500" />
                <span className="font-medium">School: {patient.school.name}</span>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <p className="text-gray-400">Contact Person:</p>
                  <p className="text-gray-300">{patient.school.contactName}</p>
                </div>
                <div>
                  <p className="text-gray-400">Contact Details:</p>
                  <div className="flex items-center space-x-3 text-gray-300">
                    <Mail className="h-3 w-3" />
                    <span>{patient.school.contactEmail}</span>
                  </div>
                  <div className="flex items-center space-x-3 text-gray-300">
                    <Phone className="h-3 w-3" />
                    <span>{patient.school.contactPhone}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Brief History */}
            <div className="max-w-2xl">
              <p className="text-gray-400 text-sm">
                Brief History:
              </p>
              <p className="text-gray-300 mt-1">
                {patient.history}
              </p>
            </div>
          </div>
        </div>

        {/* Edit Button */}
        <Button onClick={onEdit}>
          Edit Details
        </Button>
      </div>
    </div>
  );
}