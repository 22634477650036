import { Clock, CheckCircle2, AlertTriangle, Calendar, Brain, Stethoscope } from 'lucide-react';
import { cn } from '../lib/utils';

interface PatientCardProps {
  patient: {
    id: number;
    name: string;
    dateOfBirth: string;
    status: string;
    lastActivity: string;
    assessmentProgress?: number;
    priority: string;
    notes: string;
    type: 'adhd_assessment' | 'general';
    parentFormStatus?: string;
    teacherFormStatus?: string;
  };
  selected: boolean;
  onClick: () => void;
}

export default function PatientCard({ patient, selected, onClick }: PatientCardProps) {
  const statusConfig = {
    new_referral: {
      icon: <AlertTriangle className="h-4 w-4 text-purple-500" />,
      text: 'New Referral',
      bgColor: 'bg-purple-500/10',
      textColor: 'text-purple-500'
    },
    assessment_ongoing: {
      icon: <Clock className="h-4 w-4 text-yellow-500" />,
      text: 'Assessment Ongoing',
      bgColor: 'bg-yellow-500/10',
      textColor: 'text-yellow-500'
    },
    completed: {
      icon: <CheckCircle2 className="h-4 w-4 text-green-500" />,
      text: 'Completed',
      bgColor: 'bg-green-500/10',
      textColor: 'text-green-500'
    },
    general: {
      icon: <Stethoscope className="h-4 w-4 text-blue-500" />,
      text: 'General Patient',
      bgColor: 'bg-blue-500/10',
      textColor: 'text-blue-500'
    }
  };

  const status = statusConfig[patient.status as keyof typeof statusConfig] || statusConfig.general;
  const age = new Date().getFullYear() - new Date(patient.dateOfBirth).getFullYear();

  return (
    <button
      onClick={onClick}
      className={cn(
        'w-full text-left p-4 rounded-lg border transition-all duration-200',
        selected
          ? 'bg-blue-500/20 border-blue-500'
          : 'bg-white/5 border-white/10 hover:border-blue-500/50'
      )}
    >
      <div className="flex justify-between items-start mb-3">
        <div>
          <h3 className="font-medium text-white">{patient.name}</h3>
          <p className="text-sm text-gray-400">Age: {age} years</p>
        </div>
        <div className={cn('px-2 py-1 rounded-full text-xs flex items-center space-x-1', status.bgColor, status.textColor)}>
          {status.icon}
          <span>{status.text}</span>
        </div>
      </div>
      
      {/* Progress Bar - Only show for ADHD assessment patients */}
      {patient.type === 'adhd_assessment' && typeof patient.assessmentProgress === 'number' && (
        <div className="mb-3">
          <div className="flex justify-between text-xs mb-1">
            <span className="text-gray-400">Assessment Progress</span>
            <span className="text-gray-400">{patient.assessmentProgress}%</span>
          </div>
          <div className="w-full bg-white/10 rounded-full h-1.5">
            <div
              className="bg-blue-500 h-1.5 rounded-full transition-all duration-300"
              style={{ width: `${patient.assessmentProgress}%` }}
            />
          </div>
        </div>
      )}

      <div className="flex items-center justify-between text-xs text-gray-400">
        <div className="flex items-center">
          <Calendar className="h-3 w-3 mr-1" />
          <span>Last activity: {new Date(patient.lastActivity).toLocaleDateString()}</span>
        </div>
        {patient.priority === 'high' && (
          <span className="text-red-400">High Priority</span>
        )}
      </div>

      {/* Patient Type Indicator */}
      <div className="mt-3 flex items-center text-xs">
        {patient.type === 'adhd_assessment' ? (
          <span className="flex items-center text-blue-400">
            <Brain className="h-3 w-3 mr-1" />
            ADHD Assessment
          </span>
        ) : (
          <span className="flex items-center text-purple-400">
            <Stethoscope className="h-3 w-3 mr-1" />
            General Care
          </span>
        )}
      </div>
    </button>
  );
}