import { useState } from 'react';
import { Users, Mail, Search, Filter, BookOpen, ArrowLeft, Stethoscope, Brain, Clock } from 'lucide-react';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import PatientCard from '../components/PatientCard';
import ClinicalRecordsSection from '../components/ClinicalRecordsSection';
import DetailedAssessmentView from '../components/DetailedAssessmentView';
import DetailedDevelopmentHistory from '../components/DetailedDevelopmentHistory';
import AIReportGenerator from '../components/AIReportGenerator';
import { parentQuestions, teacherQuestions } from '../data/assessmentQuestions';
import ConnersFormLinks from '../components/ConnersFormLinks';

// Mock patient data
const mockPatients = [
  {
    id: 1,
    name: 'Aki Tanaka',
    dateOfBirth: '2016-05-15',
    status: 'assessment_ongoing',
    lastActivity: '2024-02-22',
    assessmentProgress: 100,
    priority: 'high',
    notes: 'ADHD assessment completed',
    type: 'adhd_assessment',
    conners_parent_link: 'https://example.com/conners/parent/123',
    conners_teacher_link: 'https://example.com/conners/teacher/123',
    assessmentData: {
      parent: {
        attention: 18,
        hyperactivity: 20,
        oppositional: 12,
        total: 50,
        responses: {
          'p1': 'Often',
          'p2': 'Very Often',
          // ... more responses
        }
      },
      teacher: {
        attention: 16,
        hyperactivity: 19,
        oppositional: 10,
        total: 45,
        responses: {
          't1': 'Often',
          't2': 'Sometimes',
          // ... more responses
        }
      },
      development: {
        completed: true,
        responses: {
          earlyDevelopment: 'Normal milestones, some hyperactivity noted from age 3',
          speechDevelopment: 'Age-appropriate language development',
          behavioralConcerns: 'Difficulty sitting still in preschool',
          socialInteractions: 'Generally good with peers but can be impulsive'
        }
      }
    }
  }
];

export default function AdminDashboard() {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [selectedPatient, setSelectedPatient] = useState(mockPatients[0]);
  const [showCorrespondence, setShowCorrespondence] = useState(false);
  const [showResources, setShowResources] = useState(false);

  const filteredPatients = mockPatients.filter(patient => {
    const matchesSearch = patient.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus = filterStatus === 'all' || patient.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  const handleUpdateConnersLinks = (patientId: number, links: { parentLink?: string; teacherLink?: string }) => {
    // In a real app, this would update the database
    console.log('Updating Conners links for patient:', patientId, links);
  };

  return (
    <div className="pt-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-white">Clinical Dashboard</h1>
          <div className="flex items-center space-x-4">
            {(showCorrespondence || showResources) && (
              <Button
                variant="outline"
                onClick={() => {
                  setShowCorrespondence(false);
                  setShowResources(false);
                }}
              >
                <ArrowLeft className="h-4 w-4 mr-2" />
                Back to Patients
              </Button>
            )}
            <Button
              variant={!showCorrespondence && !showResources ? 'default' : 'outline'}
              onClick={() => {
                setShowCorrespondence(false);
                setShowResources(false);
              }}
            >
              <Users className="h-4 w-4 mr-2" />
              Patients
            </Button>
            <Button
              variant={showCorrespondence ? 'default' : 'outline'}
              onClick={() => {
                setShowCorrespondence(true);
                setShowResources(false);
              }}
            >
              <Mail className="h-4 w-4 mr-2" />
              Correspondence
            </Button>
            <Button
              variant={showResources ? 'default' : 'outline'}
              onClick={() => {
                setShowResources(true);
                setShowCorrespondence(false);
              }}
            >
              <BookOpen className="h-4 w-4 mr-2" />
              Resources
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-6">
          {/* Patient List */}
          <div className="col-span-12 md:col-span-4 space-y-4">
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <div className="relative flex-grow">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  <Input
                    placeholder="Search patients..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10"
                  />
                </div>
                <div className="relative">
                  <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  <select
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    className="pl-10 h-10 rounded-md border bg-white/10 border-white/20 text-white px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="all">All Patients</option>
                    <option value="adhd_assessment">ADHD Assessment</option>
                    <option value="general">General Patients</option>
                    <option value="new_referral">New Referrals</option>
                  </select>
                </div>
              </div>

              <div className="space-y-3">
                {filteredPatients.map((patient) => (
                  <PatientCard
                    key={patient.id}
                    patient={patient}
                    selected={selectedPatient?.id === patient.id}
                    onClick={() => setSelectedPatient(patient)}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Patient Details */}
          <div className="col-span-12 md:col-span-8 space-y-6">
            {selectedPatient && (
              <>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <h2 className="text-xl font-semibold text-white">
                      {selectedPatient.name}
                    </h2>
                    {selectedPatient.type === 'adhd_assessment' ? (
                      <span className="flex items-center text-blue-500">
                        <Brain className="w-4 h-4 mr-1" />
                        ADHD Assessment
                      </span>
                    ) : (
                      <span className="flex items-center text-purple-500">
                        <Stethoscope className="w-4 h-4 mr-1" />
                        General Patient
                      </span>
                    )}
                  </div>
                  <div className="flex items-center text-gray-400">
                    <Clock className="w-4 h-4 mr-2" />
                    Last updated: {new Date(selectedPatient.lastActivity).toLocaleDateString()}
                  </div>
                </div>

                {/* Conners Form Links */}
                <ConnersFormLinks
                  parentLink={selectedPatient.conners_parent_link}
                  teacherLink={selectedPatient.conners_teacher_link}
                  onUpdateLinks={(links) => handleUpdateConnersLinks(selectedPatient.id, links)}
                  isClinicianView={true}
                />

                {/* Clinical Records Section */}
                <ClinicalRecordsSection patient={selectedPatient} />

                {/* Assessment Results */}
                {selectedPatient.type === 'adhd_assessment' && selectedPatient.assessmentData && (
                  <>
                    <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
                      <h3 className="text-lg font-semibold text-white mb-6">Assessment Results</h3>
                      
                      <div className="space-y-6">
                        {/* Parent Assessment Details */}
                        <DetailedAssessmentView
                          title="Parent Assessment Details"
                          scores={selectedPatient.assessmentData.parent}
                          responses={selectedPatient.assessmentData.parent.responses}
                          questions={parentQuestions}
                        />

                        {/* Teacher Assessment Details */}
                        <DetailedAssessmentView
                          title="Teacher Assessment Details"
                          scores={selectedPatient.assessmentData.teacher}
                          responses={selectedPatient.assessmentData.teacher.responses}
                          questions={teacherQuestions}
                        />

                        {/* Development History Details */}
                        {selectedPatient.assessmentData.development.completed && (
                          <DetailedDevelopmentHistory
                            responses={selectedPatient.assessmentData.development.responses}
                          />
                        )}
                      </div>
                    </div>

                    {/* AI Report Generator */}
                    <AIReportGenerator patient={selectedPatient} />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}