interface Question {
  id: string;
  text: string;
  category: string;
  options: string[];
  type?: 'rating' | 'text';
}

const ratingOptions = [
  'Never/Rarely',
  'Sometimes',
  'Often',
  'Very Often'
];

export const parentQuestions: Question[] = [
  // Attention Questions (0-8)
  {
    id: 'p1',
    text: 'Fails to give attention to details or makes careless mistakes.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p2',
    text: 'Has difficulty sustaining attention in tasks or play activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p3',
    text: 'Does not seem to listen when spoken to directly.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p4',
    text: 'Does not follow through on instructions and fails to finish work.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p5',
    text: 'Has difficulty organizing tasks and activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p6',
    text: 'Avoids tasks (e.g., homework) that require mental effort.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p7',
    text: 'Loses things necessary for tasks or activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p8',
    text: 'Is easily distracted.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p9',
    text: 'Is forgetful in daily activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },

  // Hyperactivity/Impulsivity Questions (9-17)
  {
    id: 'p10',
    text: 'Fidgets with hands or feet or squirms in seat.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p11',
    text: 'Leaves seat when remaining seated is expected.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p12',
    text: 'Runs about or climbs in situations in which it is inappropriate.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p13',
    text: 'Has difficulty playing or engaging in leisure activities quietly.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p14',
    text: 'Is "on the go" or acts as if "driven by a motor."',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p15',
    text: 'Talks excessively.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p16',
    text: 'Blurts out answers before questions have been completed.',
    category: 'Impulsivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p17',
    text: 'Has difficulty awaiting turn.',
    category: 'Impulsivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p18',
    text: 'Interrupts or intrudes on others.',
    category: 'Impulsivity',
    options: ratingOptions,
    type: 'rating'
  },

  // Behavior Questions (18-25)
  {
    id: 'p19',
    text: 'Loses temper.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p20',
    text: 'Argues with adults.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p21',
    text: 'Actively defies/refuses to comply with adult requests or rules.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p22',
    text: 'Deliberately annoys people.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p23',
    text: 'Blames others for his/her mistakes or misbehavior.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p24',
    text: 'Is touchy or easily annoyed by others.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p25',
    text: 'Is angry and resentful.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 'p26',
    text: 'Is spiteful or vindictive.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },

  // Progress Questions (Text Input)
  {
    id: 'p27',
    text: 'Please comment on this child\'s learning progress:',
    category: 'Progress',
    options: [],
    type: 'text'
  },
  {
    id: 'p28',
    text: 'Please comment on this child\'s social interaction and relationships with peers/friends:',
    category: 'Progress',
    options: [],
    type: 'text'
  }
];

export const teacherQuestions: Question[] = [
  // Attention Questions (0-8)
  {
    id: 't1',
    text: 'Fails to give attention to details or makes careless mistakes.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't2',
    text: 'Has difficulty sustaining attention in tasks or play activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't3',
    text: 'Does not seem to listen when spoken to directly.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't4',
    text: 'Does not follow through on instructions and fails to finish work.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't5',
    text: 'Has difficulty organizing tasks and activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't6',
    text: 'Avoids tasks (e.g., homework) that require mental effort.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't7',
    text: 'Loses things necessary for tasks or activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't8',
    text: 'Is easily distracted.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't9',
    text: 'Is forgetful in daily activities.',
    category: 'Attention',
    options: ratingOptions,
    type: 'rating'
  },

  // Hyperactivity/Impulsivity Questions (9-17)
  {
    id: 't10',
    text: 'Fidgets with hands or feet or squirms in seat.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't11',
    text: 'Leaves seat when remaining seated is expected.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't12',
    text: 'Runs about or climbs in situations in which it is inappropriate.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't13',
    text: 'Has difficulty playing or engaging in leisure activities quietly.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't14',
    text: 'Is "on the go" or acts as if "driven by a motor."',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't15',
    text: 'Talks excessively.',
    category: 'Hyperactivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't16',
    text: 'Blurts out answers before questions have been completed.',
    category: 'Impulsivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't17',
    text: 'Has difficulty awaiting turn.',
    category: 'Impulsivity',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't18',
    text: 'Interrupts or intrudes on others.',
    category: 'Impulsivity',
    options: ratingOptions,
    type: 'rating'
  },

  // Behavior Questions (18-25)
  {
    id: 't19',
    text: 'Loses temper.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't20',
    text: 'Argues with adults.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't21',
    text: 'Actively defies/refuses to comply with adult requests or rules.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't22',
    text: 'Deliberately annoys people.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't23',
    text: 'Blames others for his/her mistakes or misbehavior.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't24',
    text: 'Is touchy or easily annoyed by others.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't25',
    text: 'Is angry and resentful.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },
  {
    id: 't26',
    text: 'Is spiteful or vindictive.',
    category: 'Behavior',
    options: ratingOptions,
    type: 'rating'
  },

  // Progress Questions (Text Input)
  {
    id: 't27',
    text: 'Please comment on this child\'s learning progress:',
    category: 'Progress',
    options: [],
    type: 'text'
  },
  {
    id: 't28',
    text: 'Please comment on this child\'s social interaction and relationships with peers/friends:',
    category: 'Progress',
    options: [],
    type: 'text'
  }
];