import OpenAI from 'openai';
import { toast } from 'react-hot-toast';
import { supabase } from './supabase';

interface AssessmentData {
  name: string;
  age: number;
  assessmentResults: {
    parentForm: {
      score: number;
      severity: string;
    };
    teacherForm: {
      score: number;
      severity: string;
    };
  };
}

let openaiInstance: OpenAI | null = null;

async function getOpenAIKey(): Promise<string | null> {
  try {
    const { data, error } = await supabase
      .from('settings')
      .select('openai_key')
      .single();

    if (error) throw error;
    return data?.openai_key || null;
  } catch (error) {
    console.error('Error fetching OpenAI key:', error);
    return null;
  }
}

async function getOpenAIInstance(): Promise<OpenAI | null> {
  if (openaiInstance) return openaiInstance;

  const apiKey = await getOpenAIKey();
  if (!apiKey) {
    toast.error('OpenAI API key is not configured. Please contact an administrator.');
    return null;
  }

  openaiInstance = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true // Only for development
  });

  return openaiInstance;
}

export async function generateReport(patientData: AssessmentData): Promise<string | null> {
  const openai = await getOpenAIInstance();
  if (!openai) return null;

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: `You are a professional medical report writer specializing in ADHD assessments. 
          Generate a detailed, professional report based on the assessment data provided.
          Use formal medical language while maintaining clarity.
          Be specific about observations and recommendations.
          Include evidence-based treatment suggestions when appropriate.`
        },
        {
          role: "user",
          content: `Generate a detailed ADHD assessment report for the following patient:
            
            PATIENT INFORMATION
            ------------------
            Name: ${patientData.name}
            Age: ${patientData.age}
            
            ASSESSMENT SCORES
            ----------------
            Parent Assessment:
            - Score: ${patientData.assessmentResults.parentForm.score}/78
            - Severity: ${patientData.assessmentResults.parentForm.severity}
            
            Teacher Assessment:
            - Score: ${patientData.assessmentResults.teacherForm.score}/78
            - Severity: ${patientData.assessmentResults.teacherForm.severity}
            
            Please structure the report with the following sections:
            
            1. EXECUTIVE SUMMARY
            - Brief overview of the assessment
            - Primary findings
            - Initial diagnostic impression
            
            2. DETAILED ASSESSMENT FINDINGS
            - Parent assessment analysis
            - Teacher assessment analysis
            - Behavioral observations
            - Impact on daily functioning
            
            3. DIAGNOSTIC CONSIDERATIONS
            - DSM-5 criteria evaluation
            - Differential diagnoses
            - Comorbidity considerations
            
            4. RECOMMENDATIONS
            - Treatment options
            - Educational accommodations
            - Behavioral interventions
            - Parent strategies
            
            5. FOLLOW-UP PLAN
            - Monitoring schedule
            - Treatment milestones
            - Success metrics
            
            Sign the report as:
            
            Dr. Anwar Haq
            MBBS, DCH, MRCPI, FRCPI, FRCPCH (UK)
            Consultant Pediatrician
            Specialist in Child Development and Neurodevelopmental Disorders`
        }
      ]
    });

    return completion.choices[0]?.message?.content || null;
  } catch (error) {
    console.error('Error generating report:', error);
    toast.error('Failed to generate report. Please try again.');
    return null;
  }
}