import { Helmet } from 'react-helmet-async';
import { useSEO } from '../hooks/useSEO';
import { Globe, Heart, Users, Building2, CheckCircle2, ArrowRight, Phone } from 'lucide-react';
import Button from '../components/ui/Button';

const expertise = [
  {
    title: 'Cardiology and Heart Surgery',
    description: 'Access to top heart surgeons specializing in procedures like valve repair and off-pump bypass surgery under local anesthesia.'
  },
  {
    title: 'Aesthetic Medicine and Dermatology',
    description: 'Connect with renowned specialists for cosmetic procedures and skin treatments.'
  },
  {
    title: 'Specialist Referrals',
    description: "Whether it's orthopedics, neurology, or any other specialty, we can find the best provider suited to your needs."
  }
];

const services = [
  {
    title: 'Specialist Matching',
    description: 'We connect you with leading consultants and surgeons across a wide range of specialties.'
  },
  {
    title: 'Medical Coordination',
    description: 'We handle appointment scheduling, hospital admissions, and coordination between healthcare providers.'
  },
  {
    title: 'Second Opinions',
    description: 'Access to expert second opinions to ensure you have all the information needed.'
  },
  {
    title: 'Logistical Support',
    description: 'Assistance with travel arrangements, accommodation, and other necessities.'
  }
];

const whyChoose = [
  {
    title: 'Expert Knowledge',
    description: 'Our team comprises seasoned medical professionals who are continually engaged with the healthcare community.',
    icon: Heart
  },
  {
    title: 'Insider Access',
    description: 'Being constantly in touch with nurses, junior doctors, and consultants, we have up-to-date information.',
    icon: Users
  },
  {
    title: 'Cost-Effective',
    description: 'By helping you find the right resources and specialists the first time, we save you time and money.',
    icon: Building2
  }
];

export default function InternationalPatients() {
  const { pageData } = useSEO('international-patients');

  return (
    <>
      <Helmet>
        <title>{pageData?.metaTitle || 'International Patients | PRIVA Dr.'}</title>
        <meta 
          name="description" 
          content={pageData?.metaDescription || "Expert assistance for international patients seeking medical care in the UK. Connect with top specialists and receive comprehensive support throughout your healthcare journey."}
        />
        <meta name="keywords" content={pageData?.keywords} />
      </Helmet>

      <div className="min-h-screen bg-[#0F1117]">
        {/* Hero Section */}
        <div className="relative min-h-[60vh] flex items-center">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1538108149393-fbbd81895907?q=80&w=2000&auto=format&fit=crop"
              alt="International Healthcare"
              className="w-full h-full object-cover opacity-10"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
          </div>
          
          <div className="relative container mx-auto px-4 py-20 text-center">
            <div className="flex items-center justify-center mb-6">
              <Globe className="h-8 w-8 text-blue-500 mr-3" />
              <h1 className="text-4xl md:text-5xl font-bold text-white">
                Your Trusted Partner for International Patients
              </h1>
            </div>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Connecting You with the Best Healthcare Professionals in the UK
            </p>
            <Button size="lg">
              Start Your Healthcare Journey
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-20">
          {/* Introduction */}
          <div className="max-w-4xl mx-auto text-center mb-20">
            <p className="text-lg text-gray-300 leading-relaxed">
              At <span className="text-white font-medium">PrivaDr</span>, we specialize in assisting international patients seeking top-tier medical care in the United Kingdom. As London medical graduates with extensive experience in both NHS and private hospitals, we understand the UK healthcare system in-depth. Our insider knowledge and professional networks enable us to connect you with the finest medical specialists who may not be easily found through online searches or reviews.
            </p>
          </div>

          {/* Why Choose Us */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
            {whyChoose.map((item, index) => {
              const Icon = item.icon;
              return (
                <div
                  key={index}
                  className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-8 hover:bg-white/[0.05] transition-all duration-300"
                >
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="p-2 rounded-lg bg-blue-500/10">
                      <Icon className="w-6 h-6 text-blue-400" />
                    </div>
                    <h3 className="text-lg font-medium text-white">{item.title}</h3>
                  </div>
                  <p className="text-gray-400">{item.description}</p>
                </div>
              );
            })}
          </div>

          {/* Services */}
          <div className="max-w-4xl mx-auto mb-20">
            <h2 className="text-2xl font-light text-white text-center mb-12">Our Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6"
                >
                  <h3 className="text-lg font-medium text-white mb-2">{service.title}</h3>
                  <p className="text-gray-400">{service.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Areas of Expertise */}
          <div className="max-w-4xl mx-auto mb-20">
            <h2 className="text-2xl font-light text-white text-center mb-12">Areas of Expertise</h2>
            <div className="space-y-6">
              {expertise.map((area, index) => (
                <div
                  key={index}
                  className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6"
                >
                  <div className="flex items-start">
                    <CheckCircle2 className="w-5 h-5 text-blue-400 mt-1 mr-3 flex-shrink-0" />
                    <div>
                      <h3 className="text-lg font-medium text-white mb-2">{area.title}</h3>
                      <p className="text-gray-400">{area.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-2xl font-light text-white mb-6">Get in Touch</h2>
            <p className="text-gray-400 mb-8">
              Let us help you navigate the UK healthcare system with confidence.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <Button size="lg">
                Request a Consultation
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
              <Button 
                variant="outline" 
                size="lg"
                className="group"
              >
                <Phone className="w-4 h-4 mr-2 opacity-50 group-hover:opacity-100" />
                Call Us at 0800 8600 178
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}