import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Brain, CreditCard, Users, FileText, Eye } from 'lucide-react';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import { toast } from 'react-hot-toast';
import { useAuth } from '../hooks/useAuth';

interface BookingFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postcode: string;
}

export default function BookAssessment() {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<BookingFormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postcode: ''
  });

  const handleInputChange = (field: keyof BookingFormData) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      // Here you would integrate with Stripe
      // For now, we'll simulate a successful payment
      await new Promise(resolve => setTimeout(resolve, 1500));

      // Create user account
      await signUp(formData.email, 'tempPassword123', {
        role: 'patient',
        first_name: formData.firstName,
        last_name: formData.lastName,
        address: formData.address,
        city: formData.city,
        postcode: formData.postcode,
        phone: formData.phone
      });

      toast.success('Assessment package purchased successfully! Please check your email to verify your account.');
      navigate('/dashboard');
    } catch (error) {
      console.error('Payment error:', error);
      toast.error('Failed to complete purchase');
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white">Your Details</h2>
            <div className="grid grid-cols-2 gap-4">
              <Input
                label="First Name"
                value={formData.firstName}
                onChange={handleInputChange('firstName')}
                required
              />
              <Input
                label="Last Name"
                value={formData.lastName}
                onChange={handleInputChange('lastName')}
                required
              />
            </div>
            <Input
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleInputChange('email')}
              required
            />
            <Input
              label="Phone"
              type="tel"
              value={formData.phone}
              onChange={handleInputChange('phone')}
              required
            />
            <Input
              label="Address"
              value={formData.address}
              onChange={handleInputChange('address')}
              required
            />
            <div className="grid grid-cols-2 gap-4">
              <Input
                label="City"
                value={formData.city}
                onChange={handleInputChange('city')}
                required
              />
              <Input
                label="Postcode"
                value={formData.postcode}
                onChange={handleInputChange('postcode')}
                required
              />
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white">Payment</h2>
            <div className="bg-white/5 p-6 rounded-lg border border-white/10">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-4">
                  <Brain className="h-8 w-8 text-blue-500" />
                  <div>
                    <h3 className="text-lg font-medium text-white">ADHD Assessment Package</h3>
                    <p className="text-gray-400">Comprehensive evaluation and report</p>
                  </div>
                </div>
                <span className="text-2xl font-bold text-white">£450</span>
              </div>
              <ul className="space-y-3 mb-6">
                <li className="flex items-center text-gray-300">
                  <Users className="h-5 w-5 text-blue-500 mr-3" />
                  Initial Consultation
                </li>
                <li className="flex items-center text-gray-300">
                  <FileText className="h-5 w-5 text-blue-500 mr-3" />
                  Parent & Teacher Assessments
                </li>
                <li className="flex items-center text-gray-300">
                  <Eye className="h-5 w-5 text-blue-500 mr-3" />
                  Behavioral Observation
                </li>
                <li className="flex items-center text-gray-300">
                  <FileText className="h-5 w-5 text-blue-500 mr-3" />
                  Detailed Report & Recommendations
                </li>
              </ul>
              <Button
                onClick={handlePayment}
                className="w-full"
                disabled={loading}
              >
                <CreditCard className="h-5 w-5 mr-2" />
                {loading ? 'Processing...' : 'Pay £450'}
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="pt-20">
      <div className="max-w-4xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-12">
           {/*  <h1 className="text-3xl font-bold text-white mb-4">ADHD Assessment Package</h1> */}
          <p className="text-gray-400">
            Complete your details below to purchase the comprehensive ADHD assessment package
          </p>
        </div>

        {/* Progress Steps */}
        <div className="flex justify-center space-x-8 mb-8">
          {[
            { icon: Users, label: 'Your Details' },
            { icon: CreditCard, label: 'Payment' }
          ].map((step, index) => {
            const Icon = step.icon;
            const isActive = currentStep === index + 1;
            const isCompleted = currentStep > index + 1;

            return (
              <div
                key={index}
                className={`flex flex-col items-center space-y-2 ${
                  isActive ? 'text-blue-500' : isCompleted ? 'text-green-500' : 'text-gray-500'
                }`}
              >
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center ${
                    isActive
                      ? 'bg-blue-500/20 text-blue-500'
                      : isCompleted
                      ? 'bg-green-500/20 text-green-500'
                      : 'bg-white/10 text-gray-500'
                  }`}
                >
                  <Icon className="w-5 h-5" />
                </div>
                <span className="text-sm">{step.label}</span>
              </div>
            );
          })}
        </div>

        {/* Form Content */}
        <div className="bg-white/5 backdrop-blur-sm rounded-lg border border-white/10 p-8">
          <form className="space-y-8">
            {renderStepContent()}

            {/* Navigation Buttons */}
            <div className="flex justify-between pt-6">
              {currentStep > 1 && (
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setCurrentStep(prev => prev - 1)}
                >
                  Previous
                </Button>
              )}
              {currentStep < 2 && (
                <Button
                  type="button"
                  onClick={() => setCurrentStep(prev => prev + 1)}
                  className="ml-auto"
                >
                  Next
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
