import { Heart, Scale, Apple, CheckCircle2 } from 'lucide-react';

const services = [
  {
    title: 'Personalised Health Coaching',
    description: 'Comprehensive lifestyle medicine approach integrating evidence-based practices with personalized coaching.',
    icon: Heart,
    items: [
      'Personalised Health Coaching',
      'Mindfulness and Stress Management',
      'Nutritional Guidance',
      'Weight Management Programs',
      'Physical Activity Plans'
    ]
  },
  {
    title: 'Weight Management',
    description: 'Advanced weight management solutions combining medical expertise with lifestyle interventions.',
    icon: Scale,
    items: [
      'Mounjaro Injections',
      'Wegovy Treatment',
      'Ozempic Program',
      'Nutritional Support',
      'Lifestyle Modification'
    ]
  },
  {
    title: 'Wellness Services',
    description: 'Holistic wellness services designed to optimize your health and vitality.',
    icon: Apple,
    items: [
      'GP Private Consultation',
      'Health Screening',
      'Nutritional Assessment',
      'Stress Management',
      'Wellness Planning'
    ]
  }
];

export default function ServicesSection() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h2 className="text-2xl font-light text-white tracking-wide text-center mb-12">
        Our Services
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {services.map((service, index) => {
          const Icon = service.icon;
          return (
            <div
              key={index}
              className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-8 hover:bg-white/[0.05] transition-all duration-300"
            >
              <div className="flex items-center space-x-3 mb-6">
                <Icon className="w-5 h-5 text-blue-400 opacity-75" />
                <h3 className="text-lg font-light text-white tracking-wide">{service.title}</h3>
              </div>
              <p className="text-sm text-gray-400 mb-6 font-light leading-relaxed">
                {service.description}
              </p>
              <ul className="space-y-3">
                {service.items.map((item, idx) => (
                  <li key={idx} className="flex items-center text-sm text-gray-400 font-light">
                    <CheckCircle2 className="w-4 h-4 text-blue-400 opacity-50 mr-3 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}