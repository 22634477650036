import { useState } from 'react';
import { Link, Mail, Copy, Check } from 'lucide-react';
import Button from './ui/Button';
import { toast } from 'react-hot-toast';

interface ConnersFormLinksProps {
  parentLink?: string;
  teacherLink?: string;
  onUpdateLinks?: (links: { parentLink?: string; teacherLink?: string }) => void;
  isClinicianView?: boolean;
}

export default function ConnersFormLinks({ parentLink, teacherLink, onUpdateLinks, isClinicianView = false }: ConnersFormLinksProps) {
  const [copied, setCopied] = useState<'parent' | 'teacher' | null>(null);

  const handleCopy = async (type: 'parent' | 'teacher') => {
    const link = type === 'parent' ? parentLink : teacherLink;
    if (link) {
      await navigator.clipboard.writeText(link);
      setCopied(type);
      toast.success(`${type === 'parent' ? 'Parent' : 'Teacher'} link copied to clipboard`);
      setTimeout(() => setCopied(null), 2000);
    }
  };

  const handleSendEmail = (type: 'parent' | 'teacher') => {
    const link = type === 'parent' ? parentLink : teacherLink;
    const subject = `Conners Form - ${type === 'parent' ? 'Parent' : 'Teacher'} Assessment`;
    const body = `Please complete the Conners Form using this link: ${link}`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
      <h3 className="text-lg font-semibold text-white mb-4">Conners Assessment Forms</h3>
      
      <div className="space-y-6">
        {/* Parent Form Link */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h4 className="text-sm font-medium text-gray-300">Parent Form</h4>
            {isClinicianView && (
              <Button
                variant="outline"
                size="sm"
                onClick={() => onUpdateLinks?.({ parentLink: window.prompt('Enter parent form link:') })}
              >
                Update Link
              </Button>
            )}
          </div>
          {parentLink ? (
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={parentLink}
                readOnly
                className="flex-1 bg-white/10 border border-white/20 rounded-lg px-3 py-2 text-sm text-white"
              />
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleCopy('parent')}
              >
                {copied === 'parent' ? (
                  <Check className="h-4 w-4 text-green-500" />
                ) : (
                  <Copy className="h-4 w-4" />
                )}
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleSendEmail('parent')}
              >
                <Mail className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => window.open(parentLink, '_blank')}
              >
                <Link className="h-4 w-4" />
              </Button>
            </div>
          ) : (
            <div>
              <p className="text-sm text-gray-400">No parent form link available</p>
              <p className="text-xs text-blue-400 mt-1">Please check back within 24 hours - link will be active soon</p>
            </div>
          )}
        </div>

        {/* Teacher Form Link */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h4 className="text-sm font-medium text-gray-300">Teacher Form</h4>
            {isClinicianView && (
              <Button
                variant="outline"
                size="sm"
                onClick={() => onUpdateLinks?.({ teacherLink: window.prompt('Enter teacher form link:') })}
              >
                Update Link
              </Button>
            )}
          </div>
          {teacherLink ? (
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={teacherLink}
                readOnly
                className="flex-1 bg-white/10 border border-white/20 rounded-lg px-3 py-2 text-sm text-white"
              />
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleCopy('teacher')}
              >
                {copied === 'teacher' ? (
                  <Check className="h-4 w-4 text-green-500" />
                ) : (
                  <Copy className="h-4 w-4" />
                )}
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleSendEmail('teacher')}
              >
                <Mail className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => window.open(teacherLink, '_blank')}
              >
                <Link className="h-4 w-4" />
              </Button>
            </div>
          ) : (
            <div>
              <p className="text-sm text-gray-400">No teacher form link available</p>
              <p className="text-xs text-blue-400 mt-1">Please check back within 24 hours - link will be active soon</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}