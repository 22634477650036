import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { setupDatabase } from '../lib/setupDatabase';

interface SEOData {
  id: string;
  pagePath: string;
  metaTitle: string;
  metaDescription: string;
  keywords: string;
  h1: string;
}

export function useSEO(pagePath: string) {
  const [pageData, setPageData] = useState<SEOData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    const fetchSEO = async () => {
      try {
        // Try to fetch SEO data
        const { data, error } = await supabase
          .from('page_seo')
          .select('*')
          .eq('pagePath', pagePath)
          .single();

        if (error?.code === '42P01') {
          // Table doesn't exist, set it up
          await setupDatabase();
          
          // Try fetching again after setup
          const { data: retryData, error: retryError } = await supabase
            .from('page_seo')
            .select('*')
            .eq('pagePath', pagePath)
            .single();

          if (retryError) throw retryError;
          if (mounted) setPageData(retryData);
        } else if (error) {
          throw error;
        } else if (mounted) {
          setPageData(data);
        }
      } catch (err: any) {
        console.error('Error fetching SEO data:', err);
        if (mounted) setError(err);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    fetchSEO();

    return () => {
      mounted = false;
    };
  }, [pagePath]);

  return { pageData, loading, error };
}