import { Link } from 'react-router-dom';

const treatments = [
  { title: 'Female Hormone Issues', link: '#' },
  { title: "Children's Health", link: '#' },
  { title: 'SIBO', link: '#' },
  { title: 'Anxiety & Depression', link: '#' },
  { title: 'PCOS', link: '#' },
  { title: 'Autoimmune', link: '#' },
  { title: 'IBS', link: '#' },
  { title: 'Chronic Fatigue', link: '#' },
  { title: 'Menopause', link: '#' },
  { title: 'Multiple Sclerosis', link: '#' },
  { title: 'GERD', link: '#' },
  { title: 'Skin Conditions', link: '#' },
  { title: 'Fertility', link: '#' },
  { title: 'Rheumatoid Arthritis', link: '#' },
  { title: 'Food Intolerances & Allergies', link: '#' },
  { title: 'Eczema', link: '#' },
  { title: 'Male Fertility', link: '#' },
  { title: 'Coeliac Disease', link: '#' },
  { title: 'Diabetes', link: '#' },
  { title: 'Psoriasis', link: '#' },
  { title: "Men's Health", link: '#' },
  { title: 'IBD', link: '#' },
  { title: 'Migraines', link: '#' },
  { title: 'More...', link: '#' },
  { title: 'Hypothyroid', link: '#' },
  { title: 'Ulcerative Colitis', link: '#' },
  { title: 'Low Immunity', link: '#' }
];

export default function TreatmentsGrid() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h2 className="text-2xl font-light text-white tracking-wide text-center mb-12">
        Treatments We Can Help With
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {treatments.map((treatment, index) => (
          <Link
            key={index}
            to={treatment.link}
            className="bg-white/[0.02] backdrop-blur-sm rounded-lg border border-white/[0.05] p-4 hover:bg-white/[0.05] transition-all duration-300 group"
          >
            <span className="text-sm text-gray-400 font-light group-hover:text-blue-400 transition-colors">
              {treatment.title}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
}