import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login';
import NeuroDevelopment from './pages/NeuroDevelopment';
import PatientDashboard from './pages/PatientDashboard';
import AdminDashboard from './pages/AdminDashboard';
import AdminPanel from './pages/AdminPanel';
import BlogPost from './pages/BlogPost';
import Doctors from './pages/Doctors';
import DoctorProfile from './pages/DoctorProfile';
import BookAssessment from './pages/BookAssessment';
import GeneralPractice from './pages/GeneralPractice';
import LifestyleMedicine from './pages/LifestyleMedicine';
import WeightLoss from './pages/WeightLoss';
import PrivaLife from './pages/PrivaLife';
import MedSpa from './pages/MedSpa';
import InternationalPatients from './pages/InternationalPatients';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';

export default function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-[#0F1117] flex flex-col">
          <Navbar />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/neurodevelopment" element={<NeuroDevelopment />} />
              <Route path="/dashboard" element={
                <ProtectedRoute allowedRoles={['patient']}>
                  <PatientDashboard />
                </ProtectedRoute>
              } />
              <Route path="/clinician-dashboard" element={
                <ProtectedRoute allowedRoles={['clinician', 'admin']}>
                  <AdminDashboard />
                </ProtectedRoute>
              } />
              <Route path="/admin-dashboard" element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <AdminPanel />
                </ProtectedRoute>
              } />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/doctors" element={<Doctors />} />
              <Route path="/doctors/:id" element={<DoctorProfile />} />
              <Route path="/book-assessment" element={<BookAssessment />} />
              <Route path="/general-practice" element={<GeneralPractice />} />
              <Route path="/lifestyle-medicine" element={<LifestyleMedicine />} />
              <Route path="/weight-loss" element={<WeightLoss />} />
              <Route path="/privalife" element={<PrivaLife />} />
              <Route path="/medspa" element={<MedSpa />} />
              <Route path="/international-patients" element={<InternationalPatients />} />
            </Routes>
          </main>
          <Footer />
        </div>
        <Toaster position="top-right" />
      </Router>
    </HelmetProvider>
  );
}