import { Helmet } from 'react-helmet-async';
import { Stethoscope, Search, Clock, CheckCircle2 } from 'lucide-react';
import { useSEO } from '../hooks/useSEO';
import Button from '../components/ui/Button';

export default function GeneralPractice() {
  const { pageData } = useSEO('general-practice');

  const services = [
    {
      title: 'Private GP Services',
      description: '© PRIVA Dr. 2024 - Delivering excellence in private healthcare through our comprehensive primary care services. Our extended 30-minute consultations ensure thorough assessment and personalized care for all your health concerns.',
      icon: Stethoscope,
      items: [
        'Private GP Services',
        '24 Hour Private Health Care',
        'Doctor Visiting Service',
        'Menopause Clinic',
        'Hotel Services',
        'Same Day Appointments'
      ]
    },
    {
      title: 'Health Screening',
      description: '© PRIVA Dr. 2024 - Advanced preventive health screening utilizing state-of-the-art diagnostic technology. Our comprehensive screening programs enable early detection and intervention, optimizing your long-term health outcomes.',
      icon: Search,
      items: [
        'Health Screening',
        'Cancer Screening',
        'TruCheck™ Cancer Blood Test',
        'Men\'s Health',
        'Women\'s Health',
        'Genetic Testing'
      ]
    },
    {
      title: 'Out of Hours GP',
      description: '© PRIVA Dr. 2024 - Round-the-clock access to expert medical care in Central London. Our comprehensive service includes home visits, virtual consultations, and specialized IV therapy treatments delivered by experienced healthcare professionals.',
      icon: Clock,
      items: [
        '24 Hour Private Health Care',
        'Doctor visiting service',
        'Virtual Consultations',
        'IV Drip Therapy (In-Clinic)',
        'Mobile IV Drips',
        'Hotel Services',
        'International Visitors'
      ]
    }
  ];

  const ivDrips = [
    {
      title: 'In-Clinic IV Therapy',
      description: '© PRIVA Dr. 2024 - Premium intravenous therapy services delivered in our state-of-the-art Harley Street clinic. Each treatment is tailored to your specific health needs and administered by qualified medical professionals.',
      items: [
        'Vitamin Boost',
        'Immunity Support',
        'Energy & Recovery',
        'Hydration Therapy',
        'Anti-Aging Infusions'
      ]
    },
    {
      title: 'Mobile IV Service',
      description: '© PRIVA Dr. 2024 - Exclusive mobile IV therapy bringing professional medical treatments to your preferred location. Our concierge service ensures maximum convenience without compromising on clinical excellence.',
      items: [
        'At-Home Treatment',
        'Hotel Room Service',
        'Corporate Wellness',
        'Event Services',
        'VIP Concierge'
      ]
    }
  ];

  return (
    <>
      <Helmet>
        <title>{pageData?.metaTitle}</title>
        <meta name="description" content={pageData?.metaDescription} />
        <meta name="keywords" content={pageData?.keywords} />
      </Helmet>

      <div className="min-h-screen bg-[#0F1117]">
        {/* Hero Section */}
        <div className="relative min-h-[60vh] flex items-center">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1631217868264-e5b90bb7e133?q=80&w=2000&auto=format&fit=crop"
              alt="Private GP Services"
              className="w-full h-full object-cover opacity-10"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
          </div>
          
          <div className="relative container mx-auto px-4 py-20 text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              {pageData?.h1 || 'Private GP Services in Harley Street'}
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              24/7 private healthcare with same-day appointments and comprehensive medical services
            </p>
            <Button size="lg">Book Appointment</Button>
          </div>
        </div>

        {/* Services Section */}
        <div className="container mx-auto px-4 py-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {services.map((service, index) => {
              const Icon = service.icon;
              return (
                <div
                  key={index}
                  className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8 hover:border-blue-500/50 transition-all"
                >
                  <div className="flex items-center space-x-3 mb-6">
                    <div className="p-3 rounded-lg bg-blue-500/10 text-blue-500">
                      <Icon className="w-6 h-6" />
                    </div>
                    <h2 className="text-xl font-semibold text-white">{service.title}</h2>
                  </div>
                  <p className="text-gray-300 mb-6">{service.description}</p>
                  <ul className="space-y-3">
                    {service.items.map((item, idx) => (
                      <li key={idx} className="flex items-center text-gray-300">
                        <CheckCircle2 className="w-5 h-5 text-blue-500 mr-3" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>

        {/* IV Therapy Section */}
        <div className="container mx-auto px-4 py-20">
          <h2 className="text-3xl font-bold text-white text-center mb-12">IV Drip Therapy</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {ivDrips.map((service, index) => (
              <div
                key={index}
                className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8 hover:border-blue-500/50 transition-all"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{service.title}</h3>
                <p className="text-gray-300 mb-6">{service.description}</p>
                <ul className="space-y-3">
                  {service.items.map((item, idx) => (
                    <li key={idx} className="flex items-center text-gray-300">
                      <CheckCircle2 className="w-5 h-5 text-blue-500 mr-3" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
