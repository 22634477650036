import { Link } from 'react-router-dom';
import { Clock, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const articles = [
  {
    title: "The Power of Lifestyle Medicine",
    description: "Discover how lifestyle changes can prevent and reverse chronic diseases. Learn about evidence-based approaches to transform your health.",
    image: "https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?q=80&w=800&auto=format&fit=crop",
    date: "March 25, 2024",
    readTime: "5 min read",
    slug: "power-of-lifestyle-medicine",
    serviceLink: {
      text: "Explore Our Lifestyle Medicine Programs",
      url: "/lifestyle-medicine"
    }
  },
  {
    title: "Nutrition as Medicine",
    description: "Understanding how food choices impact your health and wellbeing. Expert insights into therapeutic nutrition and personalized diet plans.",
    image: "https://images.unsplash.com/photo-1490645935967-10de6ba17061?q=80&w=800&auto=format&fit=crop",
    date: "March 24, 2024",
    readTime: "6 min read",
    slug: "nutrition-as-medicine",
    serviceLink: {
      text: "Book a Nutrition Consultation",
      url: "/lifestyle-medicine"
    }
  },
  {
    title: "Stress Management & Wellness",
    description: "Effective strategies for managing stress and improving overall wellness through lifestyle interventions.",
    image: "https://images.unsplash.com/photo-1506126613408-eca07ce68773?q=80&w=800&auto=format&fit=crop",
    date: "March 23, 2024",
    readTime: "4 min read",
    slug: "stress-management-wellness",
    serviceLink: {
      text: "Learn About Our Wellness Programs",
      url: "/lifestyle-medicine"
    }
  }
];

export default function RelatedArticles() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h2 className="text-2xl font-light text-white mb-12">Related Articles</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {articles.map((article, index) => (
          <div
            key={index}
            className="group bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] overflow-hidden hover:border-blue-500/50 transition-all"
          >
            <Link to={`/blog/${article.slug}`}>
              <div className="relative h-48 overflow-hidden">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#0F1117] to-transparent opacity-60" />
              </div>
              
              <div className="p-6">
                <h3 className="text-lg font-medium text-white mb-2 group-hover:text-blue-400 transition-colors line-clamp-2">
                  {article.title}
                </h3>
                <p className="text-sm text-gray-400 mb-4 line-clamp-2">
                  {article.description}
                </p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <span>{article.date}</span>
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-1" />
                    <span>{article.readTime}</span>
                  </div>
                </div>
              </div>
            </Link>
            
            {/* Service Link */}
            <div className="px-6 pb-6">
              <Link to={article.serviceLink.url}>
                <Button 
                  variant="outline" 
                  className="w-full text-sm font-light tracking-wide border-white/[0.05] hover:bg-white/[0.02] transition-all duration-300"
                >
                  {article.serviceLink.text}
                  <ArrowRight className="w-4 h-4 ml-2 opacity-50 group-hover:opacity-100 transition-opacity" />
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}