import { useState } from 'react';
import { Settings, Key, Users, Database, Newspaper } from 'lucide-react';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import { toast } from 'react-hot-toast';
import BlogGenerator from '../components/admin/BlogGenerator';
import APIKeyManager from '../components/admin/APIKeyManager';
import SEOManager from '../components/admin/SEOManager';
import AdminNav from '../components/admin/AdminNav';
import DatabaseSetup from '../components/admin/DatabaseSetup';

export default function AdminPanel() {
  const [activeTab, setActiveTab] = useState('settings');
  const [settings, setSettings] = useState({
    supabaseUrl: '',
    supabaseKey: '',
    serviceRoleKey: ''
  });

  const handleSaveSettings = async () => {
    try {
      // Save settings to database
      toast.success('Settings saved successfully');
    } catch (error) {
      toast.error('Failed to save settings');
    }
  };

  return (
    <div className="pt-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-white">Admin Dashboard</h1>
        </div>

        <div className="grid grid-cols-12 gap-8">
          {/* Sidebar */}
          <AdminNav activeTab={activeTab} setActiveTab={setActiveTab} />

          {/* Main Content */}
          <div className="col-span-9">
            {activeTab === 'settings' && (
              <div className="space-y-6">
                <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
                  <h2 className="text-xl font-semibold text-white mb-6">Supabase Configuration</h2>
                  <div className="space-y-4">
                    <Input
                      label="Supabase URL"
                      value={settings.supabaseUrl}
                      onChange={(e) => setSettings({ ...settings, supabaseUrl: e.target.value })}
                    />
                    <Input
                      label="Supabase Anon Key"
                      value={settings.supabaseKey}
                      onChange={(e) => setSettings({ ...settings, supabaseKey: e.target.value })}
                    />
                    <Input
                      label="Service Role Key"
                      value={settings.serviceRoleKey}
                      onChange={(e) => setSettings({ ...settings, serviceRoleKey: e.target.value })}
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <Button onClick={handleSaveSettings}>Save Settings</Button>
                </div>
              </div>
            )}

            {activeTab === 'api' && <APIKeyManager />}

            {activeTab === 'database' && <DatabaseSetup />}

            {activeTab === 'users' && (
              <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
                <h2 className="text-xl font-semibold text-white mb-6">User Management</h2>
                {/* User management content */}
              </div>
            )}

            {activeTab === 'blogs' && (
              <div className="space-y-6">
                <BlogGenerator />
              </div>
            )}

            {activeTab === 'seo' && <SEOManager />}
          </div>
        </div>
      </div>
    </div>
  );
}