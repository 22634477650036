import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { cn } from '../lib/utils';

interface DetailedDevelopmentHistoryProps {
  responses: Record<string, string>;
}

export default function DetailedDevelopmentHistory({ responses }: DetailedDevelopmentHistoryProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const sections = [
    {
      title: "Child and Family Information",
      fields: [
        { key: "childName", label: "Child's Name" },
        { key: "dateOfBirth", label: "Date of Birth" },
        { key: "parentName", label: "Parent's Name" },
        { key: "dateOfCompletion", label: "Date of Form Completion" },
        { key: "otherProfessionals", label: "Other Professionals Involved" }
      ]
    },
    {
      title: "Family Background",
      fields: [
        { key: "familyMembers", label: "Family Members" },
        { key: "familyHistoryADHD", label: "Family History of ADHD/Autism" },
        { key: "familyHistoryLearning", label: "Family History of Learning/Language Delays" },
        { key: "familyHistoryPsychiatric", label: "Family History of Psychiatric Conditions" },
        { key: "familyPersonalityTraits", label: "Notable Family Personality Traits" }
      ]
    },
    {
      title: "Pregnancy and Birth",
      fields: [
        { key: "pregnancyComplications", label: "Pregnancy/Birth Complications" },
        { key: "postnatalInterventions", label: "Postnatal Medical Interventions" },
        { key: "maternalDepression", label: "Maternal Postnatal Depression" }
      ]
    },
    {
      title: "Early Development",
      fields: [
        { key: "babyTemperament", label: "Baby Temperament" },
        { key: "earlyAttention", label: "Early Attention" },
        { key: "comfortResponse", label: "Response to Comfort" },
        { key: "separationReactions", label: "Separation Reactions" }
      ]
    },
    {
      title: "Speech Development",
      fields: [
        { key: "firstWords", label: "Age of First Words" },
        { key: "sentenceFormation", label: "Age of Sentence Formation" },
        { key: "preverbalCommunication", label: "Preverbal Communication" }
      ]
    },
    {
      title: "Milestones",
      fields: [
        { key: "walkingAge", label: "Age Started Walking" },
        { key: "postWalkingBehavior", label: "Post-Walking Behavior" },
        { key: "pottyTrainingAge", label: "Potty Training Age" },
        { key: "hearingConcerns", label: "Hearing Concerns" },
        { key: "visionConcerns", label: "Vision Concerns" },
        { key: "sleepConcerns", label: "Sleep Concerns" },
        { key: "eatingConcerns", label: "Eating Concerns" }
      ]
    },
    {
      title: "Play Activities",
      fields: [
        { key: "simpleGames", label: "Simple Games Engagement" },
        { key: "playTypes", label: "Types of Play" },
        { key: "pretendPlay", label: "Pretend Play" },
        { key: "tvImitation", label: "TV Show Imitation" }
      ]
    },
    {
      title: "Education History",
      fields: [
        { key: "nurseryIssues", label: "Nursery/Playgroup Issues" },
        { key: "primarySchoolChallenges", label: "Primary School Challenges" },
        { key: "secondarySchoolProgress", label: "Secondary School Progress" }
      ]
    },
    {
      title: "Current Concerns",
      fields: [
        { key: "currentConcerns", label: "Current Concerns" },
        { key: "concernsOnset", label: "Onset of Concerns" },
        { key: "strengths", label: "Areas of Strength" }
      ]
    },
    {
      title: "Current Skills",
      fields: [
        { key: "sleepPatterns", label: "Sleep Patterns" },
        { key: "eatingHabits", label: "Eating Habits" },
        { key: "motorSkills", label: "Motor Skills" },
        { key: "skillsLoss", label: "Skills Loss" }
      ]
    },
    {
      title: "Social Relationships",
      fields: [
        { key: "peerInteraction", label: "Peer Interaction" },
        { key: "adultInteraction", label: "Adult Interaction" },
        { key: "socialInitiation", label: "Social Initiation" },
        { key: "strangerInteraction", label: "Stranger Interaction" }
      ]
    },
    {
      title: "Social Communication",
      fields: [
        { key: "eyeContact", label: "Eye Contact" },
        { key: "gestureResponse", label: "Response to Gestures" },
        { key: "conversationAbility", label: "Conversation Ability" },
        { key: "humorUnderstanding", label: "Understanding of Humor" }
      ]
    },
    {
      title: "Emotions and Empathy",
      fields: [
        { key: "upsetResponse", label: "Response When Upset" },
        { key: "empathyUnderstanding", label: "Understanding of Empathy" }
      ]
    },
    {
      title: "Behavior and Flexibility",
      fields: [
        { key: "playAndHobbies", label: "Play and Hobbies" },
        { key: "routineChanges", label: "Response to Routine Changes" },
        { key: "physicalBehaviors", label: "Physical Behaviors" }
      ]
    },
    {
      title: "Sensory Sensitivities",
      fields: [
        { key: "sensoryIssues", label: "Sensory Sensitivities" },
        { key: "objectInteraction", label: "Object Interaction" },
        { key: "selfInjury", label: "Self-Injury Behaviors" }
      ]
    },
    {
      title: "Attention and Hyperactivity",
      fields: [
        { key: "inattention", label: "Inattention" },
        { key: "hyperactivity", label: "Hyperactivity" },
        { key: "impulsivity", label: "Impulsivity" }
      ]
    }
  ];

  return (
    <div className="bg-white/5 p-6 rounded-lg">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between text-left"
      >
        <h4 className="text-lg font-medium text-white">Development History Details</h4>
        {isExpanded ? (
          <ChevronUp className="h-5 w-5 text-gray-400" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-400" />
        )}
      </button>

      <div className={cn("mt-6 space-y-8", !isExpanded && "hidden")}>
        {sections.map((section) => (
          <div key={section.title} className="space-y-4">
            <h5 className="text-md font-medium text-white">{section.title}</h5>
            <div className="space-y-4">
              {section.fields.map((field) => (
                <div key={field.key} className="bg-white/5 p-4 rounded-lg">
                  <p className="text-sm text-gray-400 mb-2">{field.label}</p>
                  <p className="text-white">{responses[field.key] || 'Not provided'}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}