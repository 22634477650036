import { Helmet } from 'react-helmet-async';
import { useSEO } from '../hooks/useSEO';
import { Scale, Activity, Heart, CheckCircle2 } from 'lucide-react';
import Button from '../components/ui/Button';
import RelatedArticles from '../components/weight-loss/RelatedArticles';

interface Package {
  duration: string;
  weightLoss: string;
  features: string[];
  monthlyPrice: number;
  totalPrice: number;
  singlePrice: number;
}

const mounjaro: Package[] = [
  {
    duration: '3-Month Plan',
    weightLoss: '5 kg – 10 kg',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 160,
    totalPrice: 480,
    singlePrice: 200
  },
  {
    duration: '6-Month Plan',
    weightLoss: '10 kg – 15 kg',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 160,
    totalPrice: 960,
    singlePrice: 200
  },
  {
    duration: '12-Month Plan',
    weightLoss: '15 kg+',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 160,
    totalPrice: 1920,
    singlePrice: 200
  }
];

const wegovy: Package[] = [
  {
    duration: '3-Month Plan',
    weightLoss: '5 kg – 10 kg',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 130,
    totalPrice: 390,
    singlePrice: 170
  },
  {
    duration: '6-Month Plan',
    weightLoss: '10 kg – 15 kg',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 130,
    totalPrice: 780,
    singlePrice: 170
  },
  {
    duration: '12-Month Plan',
    weightLoss: '15 kg+',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 130,
    totalPrice: 1560,
    singlePrice: 170
  }
];

const ozempic: Package[] = [
  {
    duration: '3-Month Plan',
    weightLoss: '5 kg – 10 kg',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 200,
    totalPrice: 600,
    singlePrice: 250
  },
  {
    duration: '6-Month Plan',
    weightLoss: '10 kg – 15 kg',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 200,
    totalPrice: 1200,
    singlePrice: 250
  },
  {
    duration: '12-Month Plan',
    weightLoss: '15 kg+',
    features: ['Blood Test', 'Monthly Follow-up Consultation'],
    monthlyPrice: 200,
    totalPrice: 2400,
    singlePrice: 250
  }
];

export default function WeightLoss() {
  const { pageData } = useSEO('weight-loss');

  const renderPackages = (packages: Package[], title: string) => (
    <div className="space-y-6">
      <h3 className="text-xl font-light text-white">{title} Packages</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {packages.map((pkg, index) => (
          <div
            key={index}
            className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6 hover:bg-white/[0.05] transition-all duration-300"
          >
            <div className="space-y-4">
              <h4 className="text-lg font-medium text-white">{pkg.duration}</h4>
              <div className="space-y-2">
                <p className="text-sm text-gray-400">Expected Weight Loss:</p>
                <p className="text-lg text-blue-400">{pkg.weightLoss}</p>
              </div>
              <ul className="space-y-2">
                {pkg.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-sm text-gray-400">
                    <CheckCircle2 className="w-4 h-4 text-blue-400 mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
              <div className="pt-4 border-t border-white/[0.05] space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Monthly:</span>
                  <span className="text-lg text-white">£{pkg.monthlyPrice}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Total:</span>
                  <span className="text-lg text-white">£{pkg.totalPrice}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Single Month:</span>
                  <span className="text-lg text-white">£{pkg.singlePrice}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{pageData?.metaTitle || 'Weight Loss Injections | PRIVA Dr.'}</title>
        <meta 
          name="description" 
          content={pageData?.metaDescription || "Expert weight loss solutions with Mounjaro, Wegovy, and Ozempic injections. Personalized treatment plans for sustainable weight management at PRIVA Dr. Harley Street."}
        />
        <meta name="keywords" content={pageData?.keywords} />
      </Helmet>

      <div className="min-h-screen bg-[#0F1117]">
        {/* Hero Section */}
        <div className="relative min-h-[60vh] flex items-center">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?q=80&w=2000&auto=format&fit=crop"
              alt="Weight Loss Treatment"
              className="w-full h-full object-cover opacity-10"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
          </div>
          
          <div className="relative container mx-auto px-4 py-20 text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Weight Loss Injections
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Our weight loss injections are designed to help you achieve sustainable weight loss by regulating appetite and promoting a healthier metabolism.
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-20">
          {/* Introduction */}
          <div className="max-w-4xl mx-auto mb-20">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6">
                <h3 className="text-lg font-medium text-white mb-4">Mounjaro</h3>
                <p className="text-sm text-gray-400">
                  Mimics hormones that regulate appetite, helping you feel fuller longer and reducing cravings.
                </p>
              </div>
              <div className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6">
                <h3 className="text-lg font-medium text-white mb-4">Wegovy</h3>
                <p className="text-sm text-gray-400">
                  Specifically formulated for weight management, it decreases hunger and increases feelings of fullness.
                </p>
              </div>
              <div className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6">
                <h3 className="text-lg font-medium text-white mb-4">Ozempic</h3>
                <p className="text-sm text-gray-400">
                  Originally for diabetes management, it aids weight loss by slowing gastric emptying, prolonging satiety after meals.
                </p>
              </div>
            </div>
          </div>

          {/* Packages */}
          <div className="space-y-20">
            {renderPackages(mounjaro, 'Mounjaro')}
            {renderPackages(wegovy, 'Wegovy')}
            {renderPackages(ozempic, 'Ozempic')}
          </div>

          {/* Why Choose Section */}
          <div className="max-w-4xl mx-auto mt-20 text-center">
            <h2 className="text-2xl font-light text-white mb-6">Why Choose PrivaDr by CosmeDocs?</h2>
            <p className="text-gray-400">
              PrivaDr by CosmeDocs is a renowned name in the aesthetic and medical wellness industry. Our clinic on Harley Street in London is equipped with modern facilities and state-of-the-art practice rooms. CosmeDocs is also a well-known trainer to physicians from around the globe.
            </p>
          </div>
        </div>

        {/* Related Articles */}
        <RelatedArticles />
      </div>
    </>
  );
}