import Button from '../ui/Button';
import { Phone, ArrowRight } from 'lucide-react';

export default function VirtualClinicSection() {
  return (
    <div className="container mx-auto px-4 py-20">
      <div className="relative">
        <div className="relative bg-white/[0.02] backdrop-blur-sm rounded-2xl border border-white/[0.05] p-8 md:p-12">
          <div className="max-w-4xl mx-auto space-y-16">
            {/* Virtual Clinic Section */}
            <div className="space-y-12">
              <div className="flex flex-col items-center text-center">
                <h2 className="text-2xl font-light text-white tracking-wide mb-6">
                  Virtual Clinic
                </h2>
                <p className="text-base text-gray-400 leading-relaxed max-w-2xl font-light">
                  With PrivaDr's Virtual Clinic, you can embark on your health transformation journey from the comfort of your home, no matter where you are in the world. Over 90% of our clients improve their health through our virtual services, receiving care equivalent to our in-person appointments.
                </p>
                <p className="text-base text-gray-400 leading-relaxed max-w-2xl mt-4 font-light">
                  We've successfully assisted clients across multiple countries, and we're ready to help you achieve your health goals with the same level of personalized, high-quality care.
                </p>
              </div>
              <div className="grid md:grid-cols-2 gap-6">
                <div className="bg-white/[0.02] rounded-xl p-6 border border-white/[0.05]">
                  <div className="text-4xl font-light text-blue-400 mb-2">90%</div>
                  <p className="text-sm text-gray-400 font-light">of our clients improve their health through our virtual services</p>
                </div>
                <div className="bg-white/[0.02] rounded-xl p-6 border border-white/[0.05]">
                  <div className="text-4xl font-light text-blue-400 mb-2">24/7</div>
                  <p className="text-sm text-gray-400 font-light">access to personalized, high-quality care worldwide</p>
                </div>
              </div>
            </div>

            {/* Continuity Section */}
            <div className="space-y-8 border-t border-white/[0.05] pt-16">
              <div className="flex flex-col items-center text-center">
                <h2 className="text-2xl font-light text-white tracking-wide mb-6">
                  Continue Your Journey with PrivaDr Continuity
                </h2>
                <p className="text-base text-gray-400 leading-relaxed max-w-2xl font-light">
                  After completing your 6-Month Care Plan and achieving your immediate health goals, you can join our PrivaDr Continuity Membership Plan. This program ensures you stay on the right path, providing ongoing support to continue progressing in your health and wellness journey.
                </p>
              </div>
            </div>

            {/* CTA Section */}
            <div className="text-center space-y-8 border-t border-white/[0.05] pt-16">
              <div className="space-y-4">
                <h2 className="text-2xl font-light text-white tracking-wide">
                  Ready to Transform Your Health?
                </h2>
                <p className="text-base text-gray-400 font-light">
                  Your journey to optimal health and well-being starts with a single step.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <Button 
                  size="lg" 
                  className="group bg-white/[0.02] hover:bg-white/[0.05] border border-white/[0.05]"
                >
                  Book Your Free Discovery Call
                  <ArrowRight className="w-4 h-4 ml-2 opacity-50 group-hover:opacity-100" />
                </Button>
                <Button 
                  variant="outline" 
                  size="lg" 
                  className="group border-white/[0.05] hover:bg-white/[0.02]"
                >
                  <Phone className="w-4 h-4 mr-2 opacity-50 group-hover:opacity-100" />
                  Call Us at 0800 8600 178
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}