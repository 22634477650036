import { ArrowRight, Brain, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';

const doctors = [
  {
    id: 'dr-anwar-haq',
    name: 'Dr. Anwar Haq',
    title: 'Consultant Pediatrician',
    subtitle: 'Specialist in Child Development and Neurodevelopmental Disorders',
    image: 'https://raw.githubusercontent.com/medacles/medacles.github.io/main/img/dr-anwar-haq.jpg',
    qualifications: 'MBBS, DCH, MRCPI, FRCPI, FRCPCH (UK)',
    specialties: [
      'Autism Spectrum Disorder (ASD)',
      'ADHD',
      'Developmental Delays',
      'Learning Disabilities'
    ],
    experience: '40+ years clinical experience',
    description: 'Dr. Anwar Haq is a distinguished pediatrician specializing in developmental and neurodevelopmental disorders. With over four decades of clinical experience, he provides comprehensive care for children with conditions such as autism spectrum disorder (ASD), ADHD, and learning disabilities.'
  }
];

export default function Doctors() {
  return (
    <div className="pt-20">
      <div className="max-w-6xl mx-auto px-4">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-white mb-4">Our Specialists</h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Meet our team of experienced medical professionals dedicated to providing exceptional care
          </p>
        </div>

        {/* Doctors Grid */}
        <div className="grid grid-cols-1 gap-8">
          {doctors.map((doctor) => (
            <div
              key={doctor.id}
              className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8 hover:border-blue-500/50 transition-all"
            >
              <div className="flex flex-col md:flex-row gap-8">
                {/* Doctor Image */}
                <div className="relative w-48 h-48 flex-shrink-0">
                  <img
                    src={doctor.image}
                    alt={doctor.name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <div className="absolute top-4 right-4">
                    <div className="bg-blue-500/20 backdrop-blur-sm rounded-full p-2">
                      <Star className="w-5 h-5 text-blue-400" />
                    </div>
                  </div>
                </div>

                {/* Doctor Info */}
                <div className="flex-grow space-y-4">
                  <div>
                    <h2 className="text-2xl font-bold text-white">{doctor.name}</h2>
                    <p className="text-blue-400">{doctor.qualifications}</p>
                  </div>

                  <div>
                    <h3 className="text-xl text-white mb-2">{doctor.title}</h3>
                    <p className="text-gray-400">{doctor.subtitle}</p>
                  </div>

                  <p className="text-gray-300">{doctor.description}</p>

                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-400">Specialties</h4>
                    <div className="flex flex-wrap gap-2">
                      {doctor.specialties.map((specialty, index) => (
                        <span
                          key={index}
                          className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-500/10 text-blue-400"
                        >
                          <Brain className="w-4 h-4 mr-2" />
                          {specialty}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="flex items-center justify-between pt-4 mt-4 border-t border-white/10">
                    <span className="text-gray-400">{doctor.experience}</span>
                    <Link to={`/doctors/${doctor.id}`}>
                      <Button>
                        View Profile
                        <ArrowRight className="w-4 h-4 ml-2" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Join Our Team CTA */}
        <div className="mt-16 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Join Our Team</h2>
          <p className="text-gray-400 mb-8">
            We're always looking for talented medical professionals to join our practice
          </p>
          <a href="mailto:careers@privadr.com">
            <Button variant="outline" size="lg">
              Contact Us About Opportunities
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}