import { Helmet } from 'react-helmet-async';
import { useSEO } from '../hooks/useSEO';
import { Heart, Users, Building2, CheckCircle2, ArrowRight } from 'lucide-react';
import Button from '../components/ui/Button';
import RelatedArticles from '../components/privalife/RelatedArticles';

const benefits = [
  {
    title: 'Holistic Care',
    description: 'Addresses all facets of a clients health - physical, mental, and emotional.',
    icon: Heart
  },
  {
    title: 'Personalized Services',
    description: 'Offers customized care plans and direct access to healthcare professionals.',
    icon: Users
  },
  {
    title: 'Enhanced Experience',
    description: 'Provides a high level of service and convenience, strengthening client relationships.',
    icon: Building2
  }
];

export default function PrivaLife() {
  const { pageData } = useSEO('privalife');

  return (
    <>
      <Helmet>
        <title>{pageData?.metaTitle}</title>
        <meta name="description" content={pageData?.metaDescription} />
        <meta name="keywords" content={pageData?.keywords} />
      </Helmet>

      <div className="min-h-screen bg-[#0F1117]">
        {/* Hero Section */}
        <div className="relative min-h-[60vh] flex items-center">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=2000&auto=format&fit=crop"
              alt="Concierge Medicine"
              className="w-full h-full object-cover opacity-10"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
          </div>
          
          <div className="relative container mx-auto px-4 py-20 text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              {pageData?.h1 || 'PrivaLife: The Perfect Complement to PrivaDr'}
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Experience exceptional healthcare with our premium concierge medicine service
            </p>
            <Button size="lg">
              Learn More About PrivaLife
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-20">
          {/* Introduction */}
          <div className="max-w-4xl mx-auto text-center mb-20">
            <p className="text-lg text-gray-300 leading-relaxed">
              PrivaLife pairs exceptionally well with PrivaDr, emphasizing a holistic approach to health and wellness. While PrivaDr focuses on personalized medical care and consultations, PrivaLife encompasses broader aspects of wellness such as lifestyle medicine, preventive care, and comprehensive wellness programs.
            </p>
          </div>

          {/* Benefits Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
            {benefits.map((benefit, index) => {
              const Icon = benefit.icon;
              return (
                <div
                  key={index}
                  className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-8 hover:bg-white/[0.05] transition-all duration-300"
                >
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="p-2 rounded-lg bg-blue-500/10">
                      <Icon className="w-6 h-6 text-blue-400" />
                    </div>
                    <h3 className="text-lg font-medium text-white">{benefit.title}</h3>
                  </div>
                  <p className="text-gray-400">{benefit.description}</p>
                </div>
              );
            })}
          </div>

          {/* Features Section */}
          <div className="max-w-4xl mx-auto mb-20">
            <h2 className="text-2xl font-light text-white text-center mb-12">
              Comprehensive Wellness Programs
            </h2>
            <div className="space-y-6">
              {[
                'Integrates preventive care, lifestyle coaching, and health management under one umbrella',
                'Provides ongoing support for overall well-being',
                'Offers direct access to healthcare professionals',
                'Includes regular health assessments and monitoring'
              ].map((feature, index) => (
                <div
                  key={index}
                  className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6"
                >
                  <div className="flex items-start">
                    <CheckCircle2 className="w-5 h-5 text-blue-400 mt-1 mr-3 flex-shrink-0" />
                    <p className="text-gray-400">{feature}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-2xl font-light text-white mb-6">
              Transform Your Healthcare Experience
            </h2>
            <p className="text-gray-400 mb-8">
              Join PrivaLife today and experience healthcare the way it should be - personalized, comprehensive, and always available.
            </p>
            <Button size="lg">
              Get Started with PrivaLife
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </div>

        {/* Related Articles */}
        <RelatedArticles />
      </div>
    </>
  );
}