import { Link } from 'react-router-dom';
import { Brain, Users, FileText, CheckCircle2 } from 'lucide-react';
import Button from '../components/ui/Button';

export default function NeuroDevelopment() {
  const testimonials = [
    {
      text: "The assessment process was thorough and professional. The team's understanding of our child's needs made a real difference.",
      author: "Parent of 8-year-old"
    },
    {
      text: "Clear communication throughout the process. The detailed report and recommendations were incredibly helpful.",
      author: "Parent of 10-year-old"
    },
    {
      text: "Excellent support and guidance. The team's expertise in ADHD assessment is outstanding.",
      author: "Parent of 7-year-old"
    }
  ];

  const relatedBlogs = [
    {
      title: "Understanding ADHD in Children",
      description: "Early signs, symptoms, and effective management strategies for ADHD",
      image: "https://images.unsplash.com/photo-1544776193-352d25ca82cd?q=80&w=800&auto=format&fit=crop",
      date: "March 25, 2024",
      readTime: "5 min read",
      slug: "understanding-adhd-in-children"
    },
    {
      title: "Supporting Your Child's Development",
      description: "Key milestones and how to nurture healthy development",
      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?q=80&w=800&auto=format&fit=crop",
      date: "March 24, 2024",
      readTime: "6 min read",
      slug: "supporting-child-development"
    },
    {
      title: "The Role of Early Intervention",
      description: "Why early assessment and support makes a difference",
      image: "https://images.unsplash.com/photo-1516627145497-ae6968895b74?q=80&w=800&auto=format&fit=crop",
      date: "March 23, 2024",
      readTime: "4 min read",
      slug: "early-intervention-importance"
    }
  ];

  return (
    <div className="min-h-screen bg-[#0F1117]">
      {/* Hero Section */}
      <div className="relative min-h-[60vh] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1544776193-352d25ca82cd?q=80&w=2000&auto=format&fit=crop"
            alt="Child Development"
            className="w-full h-full object-cover opacity-10"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
        </div>
        
        <div className="relative container mx-auto px-4 py-20 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Specialist Child Development Services
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
            Expert assessment and support for ADHD and Autism Spectrum Disorders by experienced pediatricians
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <div className="flex items-center space-x-2 text-gray-300">
              <Users className="h-5 w-5 text-blue-500" />
              <span>Experienced Specialists</span>
            </div>
            <div className="flex items-center space-x-2 text-gray-300">
              <FileText className="h-5 w-5 text-blue-500" />
              <span>Comprehensive Reports</span>
            </div>
            <div className="flex items-center space-x-2 text-gray-300">
              <Brain className="h-5 w-5 text-blue-500" />
              <span>Evidence-Based Approach</span>
            </div>
          </div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <h2 className="text-2xl font-bold text-white mb-4">Comprehensive Neurodevelopmental Care</h2>
            <p className="text-gray-300 leading-relaxed">
              At PRIVA Dr., we understand that every child's developmental journey is unique. Our specialized neurodevelopmental services are designed to provide comprehensive assessment and support for children experiencing developmental and behavioral challenges. Our team of experienced pediatricians and specialists work together to ensure accurate diagnosis and effective management strategies.
            </p>
          </div>
          <div className="space-y-4">
            <h2 className="text-2xl font-bold text-white mb-4">Expert Multi-Disciplinary Approach</h2>
            <p className="text-gray-300 leading-relaxed">
              Our multi-disciplinary approach combines the expertise of pediatricians, speech therapists, and educational psychologists to provide holistic care. We work closely with families and schools to ensure that each child receives the support they need across all environments. Our evidence-based assessments and interventions are tailored to meet your child's specific needs and promote their optimal development.
            </p>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="container mx-auto px-4 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* ADHD Assessment */}
          <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8">
            <h2 className="text-2xl font-bold text-white mb-4">ADHD Assessment</h2>
            <p className="text-gray-300 mb-6">Comprehensive evaluation package</p>
            <ul className="space-y-4 mb-8">
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Initial Consultation
              </li>
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Parent & Teacher Assessments
              </li>
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Behavioral Observation
              </li>
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Detailed Report & Recommendations
              </li>
            </ul>
            <div className="flex items-center justify-between">
              {/* <span className="text-2xl font-bold text-white">£450</span> */}
              <a href="https://forms.gle/DNBnvT4PqABGevMN8" target="_blank">
                
                <Button>Book Assessment </Button>
              </a>
            </div>
          </div>

          {/* Autism Assessment */}
          <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8">
            <h2 className="text-2xl font-bold text-white mb-4">Autism Spectrum Assessment</h2>
            <p className="text-gray-300 mb-6">Initial consultation and evaluation</p>
            <ul className="space-y-4 mb-8">
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Developmental History
              </li>
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Social Communication Assessment
              </li>
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Sensory Profile Evaluation
              </li>
              <li className="flex items-center text-gray-300">
                <CheckCircle2 className="h-5 w-5 text-blue-500 mr-3" />
                Comprehensive Diagnostic Report
              </li>
            </ul>
            <div className="flex items-center justify-between">
              {/* <span className="text-2xl font-bold text-white">£300</span> */}
                <Link to="/book-assessment">
                <Button>Book Consultation</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Our Team Section */}
      <div className="container mx-auto px-4 py-20">
        <h2 className="text-3xl font-bold text-white text-center mb-12">Our Specialists</h2>
        <div className="grid grid-cols-1 gap-8">
          <Link
            to="/doctors/dr-anwar-haq"
            className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8 hover:border-blue-500/50 transition-all"
          >
            <div className="flex items-center space-x-6">
              <img
                src="img/paediatrics/dr-anwar-haq.png"
                alt="Dr. Anwar Haq"
                className="w-32 h-32 rounded-lg object-cover"
              />
              <div>
                <h3 className="text-xl font-bold text-white mb-2">Dr. Anwar Haq</h3>
                <p className="text-blue-400 mb-2">MBBS, DCH, MRCPI, FRCPI, FRCPCH (UK)</p>
                <p className="text-gray-300 mb-4">
                  Consultant Pediatrician with over 40 years of experience in child development and neurodevelopmental disorders.
                </p>
                <span className="text-blue-400 hover:text-blue-300 transition-colors">
                  Click for more info →
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {/* Testimonials */}
      <div className="container mx-auto px-4 py-20">
        <h2 className="text-3xl font-bold text-white text-center mb-12">What Parents Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-8"
            >
              <p className="text-gray-300 italic mb-6">"{testimonial.text}"</p>
              <p className="text-sm text-blue-400">{testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Related Blogs */}
      <div className="container mx-auto px-4 py-20">
        <h2 className="text-3xl font-bold text-white text-center mb-12">Related Articles</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {relatedBlogs.map((blog, index) => (
            <Link
              key={index}
              to={`/blog/${blog.slug}`}
              className="group bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden hover:border-blue-500/50 transition-all"
            >
              <div className="relative h-48 overflow-hidden">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#0F1117] to-transparent opacity-60" />
              </div>
              
              <div className="p-6">
                <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                  {blog.title}
                </h3>
                <p className="text-gray-400 mb-4 line-clamp-2">
                  {blog.description}
                </p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <span>{blog.date}</span>
                  <span>{blog.readTime}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
