import { Brain, Heart, Sparkles, Scale, Apple, Stethoscope } from 'lucide-react';
import { Link } from 'react-router-dom';

const blogs = [
  {
    icon: <Brain className="w-8 h-8" />,
    title: "Understanding ADHD in Children",
    description: "Early signs, symptoms, and effective management strategies for ADHD",
    category: "Neurodevelopment",
    date: "March 25, 2024",
    image: "https://images.unsplash.com/photo-1544776193-352d25ca82cd?q=80&w=800&auto=format&fit=crop",
    readTime: "5 min read",
    slug: "understanding-adhd-in-children"
  },
  {
    icon: <Scale className="w-8 h-8" />,
    title: "Sustainable Weight Loss Methods",
    description: "Evidence-based approaches for healthy and lasting weight management",
    category: "Weight Management",
    date: "March 24, 2024",
    image: "https://images.unsplash.com/photo-1517836357463-d25dfeac3438?q=80&w=800&auto=format&fit=crop",
    readTime: "4 min read",
    slug: "sustainable-weight-loss-methods"
  },
  {
    icon: <Sparkles className="w-8 h-8" />,
    title: "Advanced Skincare Treatments",
    description: "Latest innovations in medical-grade skincare and treatments",
    category: "Dermatology",
    date: "March 23, 2024",
    image: "https://images.unsplash.com/photo-1570554886111-e80fcca6a029?q=80&w=800&auto=format&fit=crop",
    readTime: "6 min read",
    slug: "advanced-skincare-treatments"
  },
  {
    icon: <Apple className="w-8 h-8" />,
    title: "Nutrition for Mental Health",
    description: "How diet affects cognitive function and emotional wellbeing",
    category: "Lifestyle Medicine",
    date: "March 22, 2024",
    image: "https://images.unsplash.com/photo-1490645935967-10de6ba17061?q=80&w=800&auto=format&fit=crop",
    readTime: "7 min read",
    slug: "nutrition-mental-health"
  },
  {
    icon: <Heart className="w-8 h-8" />,
    title: "Holistic Health Practices",
    description: "Integrating traditional wisdom with modern medicine",
    category: "Lifestyle Medicine",
    date: "March 21, 2024",
    image: "https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?q=80&w=800&auto=format&fit=crop",
    readTime: "5 min read",
    slug: "holistic-health-practices"
  },
  {
    icon: <Stethoscope className="w-8 h-8" />,
    title: "Preventive Healthcare Guide",
    description: "Essential steps for maintaining optimal health and wellness",
    category: "General Health",
    date: "March 20, 2024",
    image: "https://images.unsplash.com/photo-1505751172876-fa1923c5c528?q=80&w=800&auto=format&fit=crop",
    readTime: "6 min read",
    slug: "preventive-healthcare-guide"
  }
];

export default function BlogSection() {
  return (
    <div className="py-20 px-4">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">Latest Insights</h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Expert articles and guides to help you make informed decisions about your health and well-being
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog, index) => (
            <Link
              key={index}
              to={`/blog/${blog.slug}`}
              className="group bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden hover:border-blue-500/50 transition-all"
            >
              <div className="relative h-48 overflow-hidden">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#0F1117] to-transparent opacity-60" />
              </div>
              
              <div className="p-6">
                <div className="flex items-center space-x-2 mb-4">
                  <div className="p-2 rounded-lg bg-blue-500/10 text-blue-500">
                    {blog.icon}
                  </div>
                  <span className="text-sm font-medium text-blue-500">{blog.category}</span>
                </div>

                <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                  {blog.title}
                </h3>
                <p className="text-gray-400 mb-4 line-clamp-2">
                  {blog.description}
                </p>

                <div className="flex items-center justify-between text-sm text-gray-500">
                  <span>{blog.date}</span>
                  <span>{blog.readTime}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}