import { useState } from 'react';
import { Link } from 'react-router-dom';
import { UserCircle2, Menu, Phone, Mail, ChevronDown, Brain, Heart, Sparkles, Scale, Apple, Stethoscope } from 'lucide-react';
import Logo from './Logo';

const services = [
  {
    name: 'Neurodevelopment',
    description: 'Child development and behavioral assessments',
    icon: Brain,
    href: '/neurodevelopment'
  },
  {
    name: 'Lifestyle Medicine',
    description: 'Holistic health and wellness programs',
    icon: Heart,
    href: '#'
  },
  {
    name: 'Dermatology',
    description: 'Advanced skincare treatments',
    icon: Sparkles,
    href: '#'
  },
  {
    name: 'Weight Management',
    description: 'Personalized weight loss solutions',
    icon: Scale,
    href: '#'
  },
  {
    name: 'Nutrition',
    description: 'Expert dietary guidance',
    icon: Apple,
    href: '#'
  },
  {
    name: 'General Health',
    description: 'Comprehensive medical care',
    icon: Stethoscope,
    href: '#'
  }
];

export default function Navbar() {
  const [showServices, setShowServices] = useState(false);

  return (
    <nav className="fixed w-full z-50 bg-[#0F1117]/80 backdrop-blur-sm border-b border-white/10">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <Logo size="md" />
          </Link>

          {/* Center Menu */}
          <div className="flex items-center space-x-8">
            {/* Services Dropdown */}
            <div className="relative">
              <button
                onClick={() => setShowServices(!showServices)}
                onBlur={() => setTimeout(() => setShowServices(false), 200)}
                className="flex items-center space-x-2 text-gray-300 hover:text-white transition-colors group"
              >
                <Menu className="h-6 w-6 group-hover:text-blue-500 transition-colors" />
                <span className="text-sm font-medium">Services</span>
                <ChevronDown className={`h-4 w-4 transition-transform ${showServices ? 'rotate-180' : ''}`} />
              </button>

              {/* Services Menu */}
              {showServices && (
                <div className="absolute top-full left-0 mt-2 w-[400px] bg-[#0F1117]/95 backdrop-blur-sm border border-white/10 rounded-lg shadow-xl p-4">
                  <div className="grid grid-cols-2 gap-4">
                    {services.map((service) => {
                      const Icon = service.icon;
                      return (
                        <Link
                          key={service.name}
                          to={service.href}
                          className="flex items-start p-3 rounded-lg hover:bg-white/5 transition-colors group"
                        >
                          <div className="p-2 rounded-lg bg-white/5 text-blue-500 group-hover:bg-blue-500/10 transition-colors">
                            <Icon className="h-5 w-5" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-white">{service.name}</h3>
                            <p className="text-xs text-gray-400">{service.description}</p>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            {/* Contact Info */}
            <div className="hidden md:flex items-center space-x-6">
              <a
                href="tel:08008600178"
                className="flex items-center text-gray-300 hover:text-white transition-colors group"
              >
                <Phone className="h-5 w-5 mr-2 group-hover:text-blue-500 transition-colors" />
                <span className="text-sm">08008600178</span>
              </a>
              <a
                href="mailto:contact@privadr.com"
                className="flex items-center text-gray-300 hover:text-white transition-colors group"
              >
                <Mail className="h-5 w-5 mr-2 group-hover:text-blue-500 transition-colors" />
                <span className="text-sm">contact@privadr.com</span>
              </a>
            </div>
          </div>

          {/* Sign In */}
          <Link
            to="/login"
            className="flex items-center space-x-2 text-gray-300 hover:text-white transition-colors group"
          >
            <UserCircle2 className="h-5 w-5 group-hover:text-blue-500 transition-colors" />
            <span className="text-sm font-medium">Sign In</span>
          </Link>
        </div>
      </div>
    </nav>
  );
}