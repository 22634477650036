import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import BlogSection from '../components/home/BlogSection';
import ContactSection from '../components/home/ContactSection';

export default function Home() {
  return (
    <div className="min-h-screen bg-[#0F1117]">
      <HeroSection />
      <FeaturesSection />
      <BlogSection />
      <ContactSection />
    </div>
  );
}