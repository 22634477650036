import { supabase } from './supabase';
import { toast } from 'react-hot-toast';

export async function setupDatabase() {
  try {
    // Create tables and set up RLS
    const { error: setupError } = await supabase.rpc('setup_database');
    
    if (setupError) {
      console.error('Error setting up database:', setupError);
      return false;
    }

    // Insert initial SEO data
    const { error: insertError } = await supabase
      .from('page_seo')
      .upsert([
        {
          pagePath: 'lifestyle-medicine',
          metaTitle: 'Lifestyle Medicine | PRIVA Dr.',
          metaDescription: 'Transform your health with our comprehensive lifestyle medicine approach. Expert guidance in nutrition, weight management, and wellness optimization at PRIVA Dr. Harley Street.',
          keywords: 'lifestyle medicine, weight management, nutrition, wellness, IV therapy, London, Harley Street',
          h1: 'Lifestyle Medicine'
        },
        {
          pagePath: 'general-practice',
          metaTitle: 'Private GP Services in Harley Street | PRIVA Dr.',
          metaDescription: 'Expert private GP services in Harley Street. 24/7 healthcare, same-day appointments, and comprehensive health screenings. Book your consultation today.',
          keywords: 'private GP, Harley Street doctor, health screening, 24 hour doctor, same day appointment, London GP',
          h1: 'Private GP Services in Harley Street'
        },
        {
          pagePath: 'weight-loss',
          metaTitle: 'Weight Loss Injections & Treatment | PRIVA Dr.',
          metaDescription: 'Expert weight loss solutions with Mounjaro, Wegovy, and Ozempic injections. Personalized treatment plans for sustainable weight management at PRIVA Dr. Harley Street.',
          keywords: 'weight loss injections, Mounjaro, Wegovy, Ozempic, weight management, medical weight loss, Harley Street, London',
          h1: 'Weight Loss Injections'
        },
        {
          pagePath: 'privalife',
          metaTitle: 'PrivaLife: Premium Concierge Medicine | PRIVA Dr.',
          metaDescription: 'Experience exceptional healthcare with PrivaLife concierge medicine. Comprehensive wellness programs, personalized care, and premium medical services at PRIVA Dr. Harley Street.',
          keywords: 'concierge medicine, personalized healthcare, premium medical care, wellness programs, Harley Street, London',
          h1: 'PrivaLife: The Perfect Complement to PrivaDr'
        },
        {
          pagePath: 'medspa',
          metaTitle: 'CosmeDocs at PrivaDr | Aesthetic & Cosmetic Treatments',
          metaDescription: 'Experience excellence in aesthetic care with CosmeDocs at PrivaDr. Comprehensive range of surgical and non-surgical cosmetic treatments at our Harley Street clinic.',
          keywords: 'aesthetic clinic, cosmetic treatments, dermal fillers, botox, laser treatments, skin rejuvenation, Harley Street, London',
          h1: 'CosmeDocs at PrivaDr'
        },
        {
          pagePath: 'international-patients',
          metaTitle: 'International Patients | Healthcare in the UK | PRIVA Dr.',
          metaDescription: 'Expert assistance for international patients seeking medical care in the UK. Connect with top specialists and receive comprehensive support throughout your healthcare journey.',
          keywords: 'international patients, UK healthcare, medical specialists, private healthcare, Harley Street, London',
          h1: 'Your Trusted Partner for International Patients'
        }
      ], { onConflict: 'pagePath' });

    if (insertError) {
      console.error('Error inserting SEO data:', insertError);
      return false;
    }

    return true;
  } catch (error: any) {
    console.error('Error setting up database:', error);
    toast.error('Failed to initialize database');
    return false;
  }
}