import { useState, useEffect } from 'react';
import { FileText, Save } from 'lucide-react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import { supabase } from '../../lib/supabase';
import { toast } from 'react-hot-toast';

interface PageSEO {
  id: string;
  pagePath: string;
  metaTitle: string;
  metaDescription: string;
  keywords: string;
  h1: string;
}

export default function SEOManager() {
  const [pages, setPages] = useState<PageSEO[]>([]);
  const [selectedPage, setSelectedPage] = useState<PageSEO | null>(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const { data, error } = await supabase
        .from('page_seo')
        .select('*')
        .order('pagePath');

      if (error) throw error;
      setPages(data || []);
    } catch (error) {
      console.error('Error fetching pages:', error);
      toast.error('Failed to load pages');
    }
  };

  const handleSave = async () => {
    if (!selectedPage) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('page_seo')
        .update({
          metaTitle: selectedPage.metaTitle,
          metaDescription: selectedPage.metaDescription,
          keywords: selectedPage.keywords,
          h1: selectedPage.h1
        })
        .eq('id', selectedPage.id);

      if (error) throw error;
      toast.success('SEO data updated successfully');
      await fetchPages();
    } catch (error) {
      console.error('Error saving SEO data:', error);
      toast.error('Failed to save SEO data');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
        <h2 className="text-xl font-semibold text-white mb-6 flex items-center">
          <FileText className="w-5 h-5 mr-2" />
          Page SEO Management
        </h2>

        <div className="grid grid-cols-12 gap-6">
          {/* Page List */}
          <div className="col-span-4 space-y-4">
            <h3 className="text-lg font-medium text-white mb-4">Pages</h3>
            <div className="space-y-2">
              {pages.map((page) => (
                <button
                  key={page.id}
                  onClick={() => setSelectedPage(page)}
                  className={`w-full text-left p-3 rounded-lg transition-colors ${
                    selectedPage?.id === page.id
                      ? 'bg-blue-500/20 text-blue-500'
                      : 'text-gray-400 hover:text-white hover:bg-white/5'
                  }`}
                >
                  {page.pagePath}
                </button>
              ))}
            </div>
          </div>

          {/* SEO Form */}
          {selectedPage && (
            <div className="col-span-8 space-y-4">
              <Input
                label="Meta Title"
                value={selectedPage.metaTitle}
                onChange={(e) => setSelectedPage({ ...selectedPage, metaTitle: e.target.value })}
              />
              <Input
                label="Meta Description"
                value={selectedPage.metaDescription}
                onChange={(e) => setSelectedPage({ ...selectedPage, metaDescription: e.target.value })}
              />
              <Input
                label="Keywords"
                value={selectedPage.keywords}
                onChange={(e) => setSelectedPage({ ...selectedPage, keywords: e.target.value })}
              />
              <Input
                label="H1 Tag"
                value={selectedPage.h1}
                onChange={(e) => setSelectedPage({ ...selectedPage, h1: e.target.value })}
              />

              <div className="flex justify-end">
                <Button onClick={handleSave} disabled={saving}>
                  <Save className="w-4 h-4 mr-2" />
                  {saving ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}