import { ArrowRight, CheckCircle2, Clock, Lock, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from './ui/Button';
import { useState } from 'react';
import Input from './ui/Input';
import { toast } from 'react-hot-toast';

interface AssessmentCardProps {
  title: string;
  description: string;
  status: 'completed' | 'pending';
  progress: number;
  type: 'parent' | 'teacher' | 'development';
  locked?: boolean;
}

export default function AssessmentCard({
  title,
  description,
  status,
  progress,
  type,
  locked = false
}: AssessmentCardProps) {
  const [showTeacherInvite, setShowTeacherInvite] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState('');
  const [sending, setSending] = useState(false);

  const assessmentPath = `/assessment/${type}/${title.toLowerCase().replace(/\s+/g, '-')}`;

  const handleTeacherInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!teacherEmail) {
      toast.error('Please enter teacher\'s email');
      return;
    }

    setSending(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      toast.success('Invitation sent to teacher successfully');
      setShowTeacherInvite(false);
      setTeacherEmail('');
    } catch (error) {
      toast.error('Failed to send invitation');
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <div className="flex items-center space-x-2">
            <h3 className="text-lg font-semibold text-white">{title}</h3>
            {locked && <Lock className="w-4 h-4 text-gray-400" />}
          </div>
          <p className="text-sm text-gray-400">{description}</p>
        </div>
        {status === 'completed' ? (
          <CheckCircle2 className="w-5 h-5 text-green-500" />
        ) : (
          <Clock className="w-5 h-5 text-yellow-500" />
        )}
      </div>

      <div className="mt-4">
        <div className="flex justify-between text-sm mb-1">
          <span className="font-medium text-gray-300">Progress</span>
          <span className="text-gray-400">{progress}%</span>
        </div>
        <div className="w-full bg-white/10 rounded-full h-2">
          <div
            className="bg-gradient-to-r from-blue-500 to-purple-500 h-2 rounded-full transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      <div className="mt-6 space-y-4">
        {type === 'teacher' && !showTeacherInvite ? (
          <Button 
            variant="outline" 
            className="w-full"
            onClick={() => setShowTeacherInvite(true)}
          >
            <Mail className="w-4 h-4 mr-2" />
            Invite Teacher
          </Button>
        ) : type === 'teacher' && showTeacherInvite ? (
          <form onSubmit={handleTeacherInvite} className="space-y-4">
            <Input
              type="email"
              placeholder="Teacher's email address"
              value={teacherEmail}
              onChange={(e) => setTeacherEmail(e.target.value)}
              required
            />
            <div className="flex space-x-2">
              <Button 
                type="submit" 
                className="flex-1"
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Send Invitation'}
              </Button>
              <Button 
                type="button"
                variant="outline"
                onClick={() => setShowTeacherInvite(false)}
              >
                Cancel
              </Button>
            </div>
            <p className="text-xs text-gray-400">
              The teacher will receive an email with instructions to complete the assessment form.
              You will be notified once they submit their response.
            </p>
          </form>
        ) : (
          <Link to={assessmentPath}>
            <Button variant="outline" className="w-full">
              {status === 'completed' ? 'View Form' : 'Start Assessment'}
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}