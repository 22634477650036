import { useState } from 'react';
import { History, FileText, Image, Mail, Receipt, Plus, Download, ExternalLink } from 'lucide-react';
import Button from './ui/Button';

interface ClinicalRecordsSectionProps {
  patient: {
    id: number;
    name: string;
  };
}

export default function ClinicalRecordsSection({ patient }: ClinicalRecordsSectionProps) {
  const [activeTab, setActiveTab] = useState('history');
  const [newRecord, setNewRecord] = useState('');

  const tabs = [
    { id: 'history', label: 'History', icon: History },
    { id: 'reports', label: 'Reports', icon: FileText },
    { id: 'scans', label: 'Scans/Pics', icon: Image },
    { id: 'correspondence', label: 'Correspondence', icon: Mail },
    { id: 'invoices', label: 'Invoices', icon: Receipt }
  ];

  const handleSaveRecord = () => {
    if (newRecord.trim()) {
      // Save record logic here
      setNewRecord('');
    }
  };

  return (
    <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
      <h2 className="text-xl font-semibold text-white mb-6">Clinical Records</h2>

      {/* Tabs */}
      <div className="flex flex-wrap gap-2 border-b border-white/10 mb-6">
        {tabs.map((tab) => {
          const Icon = tab.icon;
          return (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center space-x-2 px-4 py-2 text-sm font-medium border-b-2 transition-colors ${
                activeTab === tab.id
                  ? 'border-blue-500 text-blue-500'
                  : 'border-transparent text-gray-400 hover:text-white'
              }`}
            >
              <Icon className="h-4 w-4" />
              <span>{tab.label}</span>
            </button>
          );
        })}
      </div>

      {/* Tab Content */}
      <div className="space-y-6">
        {activeTab === 'history' && (
          <div className="space-y-4">
            <textarea
              value={newRecord}
              onChange={(e) => setNewRecord(e.target.value)}
              className="w-full h-32 bg-white/10 border border-white/20 rounded-lg p-3 text-white resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Add new clinical record entry..."
            />
            <div className="flex justify-end">
              <Button onClick={handleSaveRecord}>Add Record</Button>
            </div>
          </div>
        )}

        {activeTab === 'reports' && (
          <div className="space-y-4">
            <div className="flex justify-end mb-4">
              <Button>
                <Plus className="h-4 w-4 mr-2" />
                Upload Report
              </Button>
            </div>
            <div className="bg-white/10 p-4 rounded-lg">
              <p className="text-gray-400 text-center">No reports uploaded yet</p>
            </div>
          </div>
        )}

        {activeTab === 'scans' && (
          <div className="space-y-4">
            <div className="flex justify-end mb-4">
              <Button>
                <Plus className="h-4 w-4 mr-2" />
                Upload Image
              </Button>
            </div>
            <div className="bg-white/10 p-4 rounded-lg">
              <p className="text-gray-400 text-center">No images uploaded yet</p>
            </div>
          </div>
        )}

        {activeTab === 'correspondence' && (
          <div className="space-y-4">
            <div className="flex justify-end mb-4">
              <Button>
                <Plus className="h-4 w-4 mr-2" />
                New Message
              </Button>
            </div>
            <div className="bg-white/10 p-4 rounded-lg">
              <p className="text-gray-400 text-center">No correspondence records found</p>
            </div>
          </div>
        )}

        {activeTab === 'invoices' && (
          <div className="space-y-4">
            <div className="flex justify-end mb-4">
              <Button>
                <Plus className="h-4 w-4 mr-2" />
                Generate Invoice
              </Button>
            </div>
            <div className="bg-white/10 p-4 rounded-lg">
              <p className="text-gray-400 text-center">No invoices generated</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}