import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Lock, Users, Stethoscope } from 'lucide-react';
import { toast } from 'react-hot-toast';
import Button from '../components/ui/Button';
import { useAuth } from '../hooks/useAuth';
import Input from '../components/ui/Input';

type UserType = 'patient' | 'clinician' | 'admin';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState<UserType | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const user = await signIn(email, password);
      const role = user.user_metadata?.role;
      
      if (role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDemoLogin = async (type: UserType) => {
    setLoading(true);
    try {
      let demoEmail = '';
      switch (type) {
        case 'admin':
          demoEmail = 'admin@example.com';
          break;
        case 'clinician':
          demoEmail = 'doctor@example.com';
          break;
        default:
          demoEmail = 'patient@example.com';
      }
      
      const user = await signIn(demoEmail, 'password123');
      const role = user.user_metadata?.role;
      
      if (role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Demo login error:', error);
      toast.error('Failed to login with demo account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-[calc(100vh-16rem)] items-center justify-center px-4">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <Lock className="mx-auto h-12 w-12 text-blue-500" />
          <h2 className="mt-6 text-3xl font-bold text-white">Welcome Back</h2>
          <p className="mt-2 text-gray-400">Please select your account type to continue</p>
        </div>

        {!userType ? (
          <div className="grid grid-cols-1 gap-4">
            <button
              onClick={() => setUserType('patient')}
              className="group relative flex items-center justify-center space-x-4 rounded-lg border border-white/20 bg-white/5 p-6 backdrop-blur-sm transition-all hover:border-blue-500/50 hover:bg-white/10"
            >
              <Users className="h-6 w-6 text-blue-500" />
              <div className="text-left">
                <h3 className="text-lg font-semibold text-white">Patient Portal</h3>
                <p className="text-sm text-gray-400">Access your assessment forms and results</p>
              </div>
            </button>

            <button
              onClick={() => setUserType('clinician')}
              className="group relative flex items-center justify-center space-x-4 rounded-lg border border-white/20 bg-white/5 p-6 backdrop-blur-sm transition-all hover:border-purple-500/50 hover:bg-white/10"
            >
              <Stethoscope className="h-6 w-6 text-purple-500" />
              <div className="text-left">
                <h3 className="text-lg font-semibold text-white">Clinician Portal</h3>
                <p className="text-sm text-gray-400">Manage patient assessments and reports</p>
              </div>
            </button>

            <button
              onClick={() => setUserType('admin')}
              className="group relative flex items-center justify-center space-x-4 rounded-lg border border-white/20 bg-white/5 p-6 backdrop-blur-sm transition-all hover:border-green-500/50 hover:bg-white/10"
            >
              <Lock className="h-6 w-6 text-green-500" />
              <div className="text-left">
                <h3 className="text-lg font-semibold text-white">Admin Portal</h3>
                <p className="text-sm text-gray-400">Manage system settings and users</p>
              </div>
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <Input
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            
            <Input
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => setUserType(null)}
                className="text-sm text-gray-400 hover:text-white transition-colors"
              >
                ← Back to selection
              </button>
              <a
                href="#"
                className="text-sm text-blue-400 hover:text-blue-300 transition-colors"
              >
                Forgot password?
              </a>
            </div>

            <Button
              type="submit"
              className="w-full"
              disabled={loading}
            >
              {loading ? 'Signing in...' : 'Sign in'}
            </Button>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-white/10"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-400 bg-[#0F1117]">Demo Login</span>
                </div>
              </div>

              <Button
                variant="outline"
                className="w-full mt-4"
                onClick={() => handleDemoLogin(userType)}
                disabled={loading}
              >
                Use Demo Account
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}