import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Logo from './Logo';

export default function Footer() {
  const location = useLocation();
  const { user } = useAuth();

  const getDashboardLink = () => {
    if (!user) return '/login';
    switch (user.user_metadata?.role) {
      case 'admin':
        return '/admin-dashboard';
      case 'clinician':
        return '/clinician-dashboard';
      default:
        return '/dashboard';
    }
  };

  return (
    <footer className="bg-[#0F1117]/80 backdrop-blur-sm border-t border-white/10 py-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center space-y-4">
          <Logo size="sm" />
          <div className="flex justify-center items-center space-x-8">
            <Link 
              to={getDashboardLink()}
              className="text-sm text-gray-400 hover:text-white transition-colors"
            >
              {user?.user_metadata?.role === 'clinician' ? 'Clinician Dashboard' : 
               user?.user_metadata?.role === 'admin' ? 'Admin Dashboard' : 
               'Patient Dashboard'}
            </Link>
            <Link 
              to="/doctors"
              className="text-sm text-gray-400 hover:text-white transition-colors"
            >
              Our Doctors
            </Link>
            <Link 
              to="/contact"
              className="text-sm text-gray-400 hover:text-white transition-colors"
            >
              Contact Us
            </Link>
          </div>
          <div className="text-center mt-4">
            <p className="text-sm text-gray-500">
              © {new Date().getFullYear()} PRIVA Dr. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}