import { Brain, Clock, Calendar, Share2, Bookmark, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';

export default function BlogPost() {
  const relatedPosts = [
    {
      title: "Nutrition for Brain Health",
      image: "https://images.unsplash.com/photo-1490645935967-10de6ba17061?q=80&w=800&auto=format&fit=crop",
      date: "March 21, 2024"
    },
    {
      title: "Managing ADHD in School",
      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?q=80&w=800&auto=format&fit=crop",
      date: "March 18, 2024"
    }
  ];

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <div className="relative h-[60vh] min-h-[500px] w-full">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1544776193-352d25ca82cd?q=80&w=2000&auto=format&fit=crop"
            alt="ADHD in Children"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
        </div>

        <div className="absolute bottom-0 left-0 right-0 p-8">
          <div className="max-w-4xl mx-auto">
            <Link
              to="/blog"
              className="inline-flex items-center text-sm text-gray-300 hover:text-white mb-4"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to Blog
            </Link>
            
            <div className="flex items-center space-x-4 mb-4">
              <div className="p-2 rounded-lg bg-blue-500/20 backdrop-blur-sm">
                <Brain className="h-6 w-6 text-blue-400" />
              </div>
              <span className="text-blue-400 font-medium">Neurodevelopment</span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Understanding ADHD in Children
            </h1>
            <p className="text-xl text-gray-300 mb-6">
              Early signs, symptoms, and effective management strategies for ADHD
            </p>

            <div className="flex items-center space-x-6 text-gray-400">
              <div className="flex items-center">
                <Calendar className="h-5 w-5 mr-2" />
                <span>March 25, 2024</span>
              </div>
              <div className="flex items-center">
                <Clock className="h-5 w-5 mr-2" />
                <span>5 min read</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            <img
              src="https://raw.githubusercontent.com/medacles/medacles.github.io/main/img/dr-anwar-haq.jpg"
              alt="Dr. Anwar Haq"
              className="w-12 h-12 rounded-full object-cover"
            />
            <div>
              <h3 className="text-white font-medium">Dr. Anwar Haq</h3>
              <p className="text-gray-400 text-sm">Child Development Specialist</p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Button variant="outline" size="sm">
              <Share2 className="h-4 w-4 mr-2" />
              Share
            </Button>
            <Button variant="outline" size="sm">
              <Bookmark className="h-4 w-4 mr-2" />
              Save
            </Button>
          </div>
        </div>

        <article className="prose prose-invert prose-lg max-w-none">
          <blockquote className="text-xl italic border-l-4 border-blue-500 pl-4 my-8">
            "ADHD is not just about attention - it's about executive function, self-regulation, and the unique way a child's brain processes information."
          </blockquote>

          <h2 className="text-3xl font-bold text-white mt-12 mb-6">What is ADHD?</h2>
          <p className="text-gray-200 leading-relaxed">
            Attention Deficit Hyperactivity Disorder (ADHD) is a neurodevelopmental condition that affects children's behavior, attention, and impulse control. It's one of the most common childhood disorders, typically diagnosed in early school years.
          </p>

          <h2 className="text-3xl font-bold text-white mt-12 mb-6">Early Signs and Symptoms</h2>
          <p className="text-gray-200 leading-relaxed mb-6">
            Children with ADHD may exhibit various symptoms that can impact their daily life and academic performance. Common signs include:
          </p>
          <ul className="space-y-4 text-gray-200">
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Difficulty maintaining attention during tasks or play</span>
            </li>
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Frequent fidgeting or inability to sit still</span>
            </li>
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Impulsive behavior and difficulty waiting their turn</span>
            </li>
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Often appears not to listen when spoken to directly</span>
            </li>
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Struggles with organizing tasks and activities</span>
            </li>
          </ul>

          <div className="bg-white/5 border-l-4 border-purple-500 p-6 rounded-r-lg my-12">
            <h3 className="text-xl font-semibold text-white mb-4">Did You Know?</h3>
            <p className="text-gray-300">
              ADHD affects approximately 5-7% of children worldwide, making it one of the most common neurodevelopmental disorders in childhood.
            </p>
          </div>

          <h2 className="text-3xl font-bold text-white mt-12 mb-6">Impact on Learning and Development</h2>
          <p className="text-gray-200 leading-relaxed mb-6">
            ADHD can significantly affect a child's educational experience and social relationships. Understanding these challenges is crucial for providing appropriate support:
          </p>
          <ul className="space-y-4 text-gray-200">
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Academic challenges despite normal or high intelligence</span>
            </li>
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Difficulty following instructions and completing assignments</span>
            </li>
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Struggles with peer relationships and social interactions</span>
            </li>
            <li className="flex items-start">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0"></span>
              <span>Impact on self-esteem and emotional well-being</span>
            </li>
          </ul>

          <h2 className="text-3xl font-bold text-white mt-12 mb-6">Management Strategies</h2>
          <p className="text-gray-200 leading-relaxed mb-6">
            Effective ADHD management typically involves a multi-faceted approach:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            <div className="bg-white/5 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-white mb-4">Professional Support</h3>
              <ul className="space-y-3 text-gray-200">
                <li>• Professional assessment and diagnosis</li>
                <li>• Behavioral therapy and counseling</li>
                <li>• Regular monitoring and adjustments</li>
              </ul>
            </div>
            <div className="bg-white/5 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-white mb-4">Educational Support</h3>
              <ul className="space-y-3 text-gray-200">
                <li>• Classroom accommodations</li>
                <li>• Individualized learning plans</li>
                <li>• Regular teacher communication</li>
              </ul>
            </div>
          </div>

          <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border border-blue-500/20 rounded-lg p-8 my-12">
            <h3 className="text-2xl font-bold text-white mb-6">Key Takeaways</h3>
            <ul className="space-y-4 text-gray-200">
              <li className="flex items-start">
                <span className="inline-block w-3 h-3 bg-blue-500 rounded-full mt-2 mr-4 flex-shrink-0"></span>
                <span>Early identification is crucial for better outcomes</span>
              </li>
              <li className="flex items-start">
                <span className="inline-block w-3 h-3 bg-blue-500 rounded-full mt-2 mr-4 flex-shrink-0"></span>
                <span>ADHD affects each child differently</span>
              </li>
              <li className="flex items-start">
                <span className="inline-block w-3 h-3 bg-blue-500 rounded-full mt-2 mr-4 flex-shrink-0"></span>
                <span>A comprehensive treatment approach works best</span>
              </li>
              <li className="flex items-start">
                <span className="inline-block w-3 h-3 bg-blue-500 rounded-full mt-2 mr-4 flex-shrink-0"></span>
                <span>Support from family and school is essential</span>
              </li>
            </ul>
          </div>
        </article>

        {/* Related Posts */}
        <div className="mt-12 pt-12 border-t border-white/10">
          <h2 className="text-2xl font-bold text-white mb-6">Related Articles</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {relatedPosts.map((post, index) => (
              <Link
                key={index}
                to="#"
                className="group bg-white/5 rounded-lg overflow-hidden hover:bg-white/10 transition-colors"
              >
                <div className="relative h-48">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-[#0F1117] to-transparent" />
                </div>
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                    {post.title}
                  </h3>
                  <span className="text-sm text-gray-400">{post.date}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}