import { Helmet } from 'react-helmet-async';
import { useSEO } from '../hooks/useSEO';
import RelatedArticles from '../components/medspa/RelatedArticles';
import { Sparkles, CheckCircle2 } from 'lucide-react';
import Button from '../components/ui/Button';

export default function MedSpa() {
  const { pageData } = useSEO('medspa');

  const treatments = [
    {
      category: 'Non-Surgical Treatments',
      items: [
        'Dermal Fillers',
        'Botox® Injections',
        'Chemical Peels',
        'Laser Treatments',
        'Skin Rejuvenation',
        'Non-Surgical Rhinoplasty',
        'PRP Therapy'
      ]
    },
    {
      category: 'Surgical Procedures',
      items: [
        'Liposuction',
        'Facelifts',
        'Blepharoplasty',
        'Hair Transplantation',
        'Breast Augmentation'
      ]
    },
    {
      category: 'Specialized Treatments',
      items: [
        'Hyperhidrosis Treatment',
        'Thread Lifts',
        'Micro-Needling'
      ]
    }
  ];

  return (
    <>
      <Helmet>
        <title>{pageData?.metaTitle}</title>
        <meta name="description" content={pageData?.metaDescription} />
        <meta name="keywords" content={pageData?.keywords} />
      </Helmet>

      <div className="min-h-screen bg-[#0F1117]">
        {/* Hero Section */}
        <div className="relative min-h-[60vh] flex items-center">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?q=80&w=2000&auto=format&fit=crop"
              alt="Aesthetic Treatments"
              className="w-full h-full object-cover opacity-10"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#0F1117]/50 to-[#0F1117]" />
          </div>
          
          <div className="relative container mx-auto px-4 py-20 text-center">
            <div className="flex items-center justify-center mb-6">
              <Sparkles className="h-8 w-8 text-blue-500 mr-3" />
              <h1 className="text-4xl md:text-5xl font-bold text-white">
                {pageData?.h1 || 'CosmeDocs at PrivaDr'}
              </h1>
            </div>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Experience excellence in aesthetic care with our comprehensive range of surgical and non-surgical treatments
            </p>
            <Button size="lg">Book a Consultation</Button>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-20">
          {/* Introduction */}
          <div className="max-w-4xl mx-auto text-center mb-20">
            <p className="text-lg text-gray-300 leading-relaxed">
              At CosmeDocs, our mission is to offer safe, effective, and innovative cosmetic procedures that help you look and feel your best. We combine advanced medical expertise with personalized care to ensure optimal results and patient satisfaction.
            </p>
          </div>

          {/* Treatments Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
            {treatments.map((section, index) => (
              <div
                key={index}
                className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-8 hover:bg-white/[0.05] transition-all duration-300"
              >
                <h3 className="text-xl font-medium text-white mb-6">{section.category}</h3>
                <ul className="space-y-3">
                  {section.items.map((item, idx) => (
                    <li key={idx} className="flex items-center text-gray-300">
                      <CheckCircle2 className="w-4 h-4 text-blue-400 mr-3 flex-shrink-0" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Why Choose Section */}
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-2xl font-light text-white mb-8">Why Choose CosmeDocs</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6">
                <h3 className="text-lg font-medium text-white mb-3">Experienced Practitioners</h3>
                <p className="text-gray-400">Our team consists of highly qualified doctors and aesthetic specialists with extensive experience.</p>
              </div>
              <div className="bg-white/[0.02] backdrop-blur-sm rounded-xl border border-white/[0.05] p-6">
                <h3 className="text-lg font-medium text-white mb-3">Advanced Techniques</h3>
                <p className="text-gray-400">Utilizing the latest technologies and techniques to ensure safe and effective results.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Related Articles */}
        <RelatedArticles />
      </div>
    </>
  );
}