import { useState } from 'react';
import { Key, Eye, EyeOff, Save } from 'lucide-react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import { supabase } from '../../lib/supabase';
import { toast } from 'react-hot-toast';

export default function APIKeyManager() {
  const [showOpenAIKey, setShowOpenAIKey] = useState(false);
  const [openAIKey, setOpenAIKey] = useState('');
  const [saving, setSaving] = useState(false);

  const handleSaveKeys = async () => {
    setSaving(true);
    try {
      const { error } = await supabase
        .from('settings')
        .update({ openai_key: openAIKey })
        .eq('id', 1);

      if (error) throw error;
      toast.success('API keys updated successfully');
    } catch (error) {
      console.error('Error saving API keys:', error);
      toast.error('Failed to save API keys');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
        <h2 className="text-xl font-semibold text-white mb-6 flex items-center">
          <Key className="w-5 h-5 mr-2" />
          API Keys
        </h2>

        <div className="space-y-4">
          <div className="relative">
            <Input
              label="OpenAI API Key"
              type={showOpenAIKey ? 'text' : 'password'}
              value={openAIKey}
              onChange={(e) => setOpenAIKey(e.target.value)}
              className="pr-10"
            />
            <button
              type="button"
              onClick={() => setShowOpenAIKey(!showOpenAIKey)}
              className="absolute right-3 top-[34px] text-gray-400 hover:text-white"
            >
              {showOpenAIKey ? (
                <EyeOff className="w-4 h-4" />
              ) : (
                <Eye className="w-4 h-4" />
              )}
            </button>
          </div>

          <Button onClick={handleSaveKeys} disabled={saving}>
            <Save className="w-4 h-4 mr-2" />
            {saving ? 'Saving...' : 'Save Keys'}
          </Button>
        </div>
      </div>
    </div>
  );
}