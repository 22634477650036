import { Settings, Key, Users, Database, Newspaper, FileText, Layout } from 'lucide-react';

interface AdminNavProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export default function AdminNav({ activeTab, setActiveTab }: AdminNavProps) {
  const tabs = [
    { id: 'settings', label: 'Settings', icon: Settings },
    { id: 'api', label: 'API Keys', icon: Key },
    { id: 'users', label: 'Users', icon: Users },
    { id: 'database', label: 'Database', icon: Database },
    { id: 'blogs', label: 'Auto-Blog Generator', icon: Newspaper },
    { id: 'seo', label: 'SEO Manager', icon: FileText },
    { id: 'templates', label: 'Page Templates', icon: Layout }
  ];

  return (
    <div className="col-span-3 space-y-2">
      {tabs.map((tab) => {
        const Icon = tab.icon;
        return (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`w-full flex items-center space-x-2 p-3 rounded-lg transition-colors ${
              activeTab === tab.id
                ? 'bg-blue-500/20 text-blue-500'
                : 'text-gray-400 hover:text-white hover:bg-white/5'
            }`}
          >
            <Icon className="h-5 w-5" />
            <span>{tab.label}</span>
          </button>
        );
      })}
    </div>
  );
}